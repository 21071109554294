<div class="py-2 px-4">
  <div *ngIf="isLoading" class="w-100 d-flex justify-content-center">
    <div class="spinner-border custom-spinner"
         role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="!isLoading">
    <div *ngIf="hasUserReserved; else book"
         class="h-min text-sm w-100 flex flex-column justify-content-center align-items-center mb-2">
      <strong>{{ 'event.reservation-confirmed' | translate }}</strong>
      <div class="flex flex-row items-center gap-1 justify-content-center">
        <button (click)="openModal()"
                class="custom-color text-sm py-2 px-3 rounded-full">
          {{ 'event.cancel-event-button' | translate }}
        </button>
      </div>
    </div>
    <ng-template #book>
      <div
        class="d-flex justify-content-center">
        <button (click)="openModal()"
                class="custom-color text-sm py-2 px-3 rounded-full w-50">
          <div class="flex justify-content-center">
            <b>{{ 'event.book-event-button' | translate }}</b>
<!--            <svg class="mt-0.5 w-5 h-5" fill="none" stroke="currentColor" stroke-width="1.5"-->
<!--                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path-->
<!--                d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"-->
<!--                stroke-linecap="round"-->
<!--                stroke-linejoin="round"/>-->
<!--            </svg>-->
<!--            <strong class="ml-1">{{ availableReservations }}</strong>-->
          </div>
        </button>
      </div>
    </ng-template>
  </div>
</div>

<xplorer-bowser-reservation-popup
  [toggle]="toggleModal"
  [xploreItem]="xploreItem"
  [alreadyReserved]="hasUserReserved"
  (reservationConfirmed)="onReservationEvent()"
>
</xplorer-bowser-reservation-popup>


