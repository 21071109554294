<ng-template #locationSwitchModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cambia location</h4>
  </div>
  <div class="modal-body">
    <div *ngFor="let availableTerritory of availableTerritories" class="row mx-0" (click)="changeTerritory(availableTerritory)">

      <div class="col-2 align-items-center d-flex mx-0">
        <div class="d-flex justify-content-center" *ngIf="availableTerritory.code === getCurrentTerritoryCode()">
          <svg class="w-10 h-10" fill="none" stroke="currentColor" stroke-width="1.7"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">\
            <use [attr.xlink:href]="ImageManager.checkIcon"/>
          </svg>
        </div>
      </div>

      <div class="col-8 p-2 my-2 d-flex justify-content-center">
        <img alt="Explore icon" class="h-12" routerLinkActive="tab-explore-active"
             [src]="ImageManager.getLogoXplorerFullByTerritoryCode(availableTerritory.code)">
      </div>

      <div class="col-2">

      </div>

    </div>
  </div>
</ng-template>
