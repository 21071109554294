<div *ngIf="!loading; else loadSpin" class="height-without-bar overflow-y-auto">
  <div *ngIf="errorHappened; else content" class="h-100">
    <div class="h-100 w-100 flex align-items-center">
      <div class="flex flex-column justify-content-center h-fit w-100">
        <img class='mx-auto h-36 w-36' src="../../../../assets/dizzy-robot.png">
        <p class="text-lg mx-auto">{{ 'alter.msg' | translate }}</p>
      </div>
    </div>
  </div>
  <ng-template #content>
    <div *ngIf="isMobile" class="h-100">
      <swiper (slideChange)="onSlideChange($event)" [config]="itemVerticalSwiperConfig" class="h-100">
        <ng-template *ngFor="let xploreItem of xploreItems" swiperSlide>
          <app-mobile-item-card [xploreItem]="xploreItem"></app-mobile-item-card>
        </ng-template>
      </swiper>
    </div>
    <div *ngIf="!isMobile"
         (scrolled)="onScroll()"
         [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
         [scrollWindow]="false"
         class="hidden h-100 overflow-y-auto md:block lg:px-8"
         infiniteScroll>
      <div *ngFor="let xploreItem of xploreItems"
           inViewport
           [inViewportOptions]="{threshold: 1}"
           (inViewportAction)="onXploreInViewport(xploreItem)"
           class="mx-auto my-5 rounder-l shadow-lg w-3/4 lg:w-3/5 xl:w-1/2">
        <app-item-card [xploreItem]="xploreItem"></app-item-card>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #loadSpin>
  <div class="height-without-bar flex align-items-center justify-content-center">
    <div class="spinner-border custom-big-spinner"
         role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>





