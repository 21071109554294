import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CommercialDTO,
  MultiMediaDTO,
  TagDTO,
  UserAccountDTO,
  XploreItemDTO
} from 'src/app/shared/object/xplorer-be-parsed-classes';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { Router } from "@angular/router";
import { UserService } from "../../../services/user/user.service";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../services/auth/auth.service";
import { ShareService } from "../../../services/share/share.service";
import { UserGeolocationService } from "../../../services/geolocation/user-geolocation.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {ImageManager} from "../../../shared/image-manager";
import {TerritoryService} from "../../../services/geolocation/territory.service";
import {AnalyticsService} from "../../../services/analytics/analytics.service";

SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.css']
})
export class ItemCardComponent implements OnInit, OnDestroy {

  @Input() xploreItem: XploreItemDTO;

  public imageSwiperConfig: SwiperOptions = {
    slidesPerView: 1,
    preloadImages: true,
    spaceBetween: 0,
    pagination: true,
    resistanceRatio: 0,
    navigation: true,
    autoHeight: false
  };

  distanceFromUser: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private shareService: ShareService,
    private userGeolocationService: UserGeolocationService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleGeolocation();
  }

  getImage(multimediaDTO: MultiMediaDTO) {
    return multimediaDTO?.mediaURL ? environment.IMAGES_URL + multimediaDTO.mediaURL : ImageManager.getPlaceholderImage();
  }

  getFirstLevelTags() {
    return this.xploreItem.tags.filter(tag => tag.tagLevel == 0)
  }

  favoriteButtonClicked() {
    this.authService.isUserLogged().subscribe(isUserLogged => {
        if (!isUserLogged) {
          this.router.navigate(['login']);
        } else {
          if (!this.userService.checkIfItemInFavorite(this.xploreItem.id)) {
            this.userService.addSavedXplore(this.xploreItem.id);
          } else {
            this.userService.removeFavorite(this.xploreItem.id);
          }
        }
      });
  }

  checkIfItemIsFavorite() {
    return this.userService.checkIfItemInFavorite(this.xploreItem.id)
  }

  hasOwner() {
    return this.xploreItem.itemType === 'COMMERCIAL'
      && (this.xploreItem as CommercialDTO).company
  }

  getOwnerName() {
    if (this.xploreItem.itemType === 'COMMERCIAL') {
      let commercial = this.xploreItem as CommercialDTO
      return commercial.company.name
    }
    return ''
  }

  isEvent() {
    return this.xploreItem.itemType === 'EVENT'
  }

  canShare(): boolean {
    return this.shareService.canShare();
  }

  share(xploreItem: XploreItemDTO): void {
    const title = xploreItem.name;
    const text = xploreItem.shortDescription;

    this.shareService.share(xploreItem.id, title, text,
      TerritoryService.addTerritoryCodeToUrl(environment.DEFAULT_ITEM_URL + xploreItem.id, xploreItem.territory.code)
    );
  }

  private handleGeolocation() {
    this.distanceFromUser = this.userGeolocationService.getDistanceXploreItemFromUserLocation(this.xploreItem) + ' km';
  }

  goToImmersive() {
    this.router.navigate(['immersive/' + this.xploreItem.id]);
  }

  goToSearchTag(tag: TagDTO) {
    this.router.navigate(['search/' + tag.code]);
  }

  protected readonly ImageManager = ImageManager;

  goToUserProfile(userAccount: UserAccountDTO) {
    this.router.navigate(['profile/' + userAccount.userId]);
  }
}
