import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { Subject, Observable } from 'rxjs';
import { LocalStorageKeys } from '../shared/enums/local-storage-keys';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  popup: Subject<boolean>;

  constructor() {
    this.popup = new Subject<boolean>();
  }

  public set show(value: boolean) {
    this.popup.next(value);
  }

  public get showPopup(): Observable<boolean> {
    return this.popup.asObservable();
  }

  public canAsk(): boolean {
    let strPopupDate: string = localStorage.getItem(LocalStorageKeys.PWA_POPUP_DATE);

    if (!strPopupDate) {
      return true;
    }

    if (strPopupDate && strPopupDate.length > 0) {
      let popupDate = dayjs(new Date(strPopupDate));
      if (dayjs(new Date()).isAfter(popupDate)) {
        localStorage.removeItem(LocalStorageKeys.PWA_POPUP_DATE);
        return true;
      }
    }

    return false;
  }

  public setPopupDate(): void {
    let popupDate = new Date();
    popupDate.setDate(popupDate.getDate() + 1);
    localStorage.setItem(LocalStorageKeys.PWA_POPUP_DATE, popupDate.toLocaleString());
  }

}
