<div class="flex flex-col p-3 border border-gray-400 shadow-md rounded-2xl h-full">
  <div class="custom-background-color w-12 h-12 p-1 flex justify-center items-center rounded-lg">
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="26" viewBox="0 0 33 26" fill="none">
      <g clip-path="url(#clip0_465_658)">
        <path opacity="0.8" d="M0.222656 5.55627C0.222656 3.36877 1.97265 1.55627 4.22266 1.55627H28.2227C30.4101 1.55627 32.2227 3.36877 32.2227 5.55627V21.5563C32.2227 23.8062 30.4101 25.5563 28.2227 25.5563H4.22266C1.97265 25.5563 0.222656 23.8062 0.222656 21.5563V5.55627ZM2.22266 5.55627V8.05628L14.4102 17.0563C15.4727 17.8062 16.9102 17.8062 17.9727 17.0563L30.2227 8.05628V5.55627C30.2227 4.49377 29.2852 3.55627 28.2227 3.55627H4.16016C3.09765 3.55627 2.16016 4.49377 2.16016 5.55627H2.22266ZM2.22266 10.5563V21.5563C2.22266 22.6813 3.09765 23.5563 4.22266 23.5563H28.2227C29.2852 23.5563 30.2227 22.6813 30.2227 21.5563V10.5563L19.1601 18.6813C17.4102 19.9313 14.9727 19.9313 13.2227 18.6813L2.22266 10.5563Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_465_658">
          <rect width="32" height="25.2632" fill="white" transform="translate(0.222656 0.293121)"/>
        </clipPath>
      </defs>
    </svg>
  </div>
  <dt class="mt-6 text-sm font-bold">Email</dt>
  <div class="col-span-2 my-auto">
    <dd class="mt-2 text-sm break-words">{{email}}</dd>
  </div>
</div>
