import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {XploreItemDTO} from "../../../shared/object/xplorer-be-parsed-classes";
import {UserService} from "../../../services/user/user.service";
import {XploreItemService} from "../../../services/xplore-item.service";
import {Subject} from "rxjs";
import {MetaDataService} from "../../../services/meta/meta-data.service";

@Component({
  selector: 'xplorer-bowser-favorites-page',
  templateUrl: './favorites-page.component.html',
  styleUrls: ['./favorites-page.component.css']
})
export class FavoritesPageComponent implements OnInit, OnDestroy {

  isLoading: boolean = true;

  constructor(
    private userService: UserService,
    private xploreItemService: XploreItemService,
    private metaDataService: MetaDataService
  ) { }

  private onDestroy$: Subject<void> = new Subject<void>();
  favorites: XploreItemDTO[] = [];

  ngOnInit(): void {
    this.retrieveFavorites();
    this.metaDataService.setDefaultMetaData();
  }

  retrieveFavorites(): void {
    this.userService.getFavorites()
      .subscribe(favorites => {
        if(!favorites || favorites?.length === 0) {
          this.isLoading = false;
          return;
        }
        favorites.forEach(userSavedXploreDTO => {
          this.xploreItemService.getXploreItemById(userSavedXploreDTO.xploreId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(explore => {
              this.favorites.push(explore);
              this.isLoading = false;
            });
        })
      }
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  protected readonly length = length;
}
