import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {XploreItemDTO} from "../../../shared/object/xplorer-be-parsed-classes";

@Component({
  selector: 'xplorer-bowser-xplore-item-preview',
  templateUrl: './xplore-item-preview.component.html',
  styleUrls: ['./xplore-item-preview.component.css']
})
export class XploreItemPreviewComponent implements OnInit {

  xploreItemDTO: XploreItemDTO = null;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    window.addEventListener('message', (event) => {
      try {
        this.xploreItemDTO = JSON.parse(event.data);
      } catch (e) {
        return;
      }
    });
  }
}
