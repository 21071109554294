declare var setting: any;

export class AppSetting {
    private sessionSeed: number = 0;

    get(key: string) {
        return setting[key];
    }

    getSessionSeed(){
        if(this.sessionSeed === 0){
            this.sessionSeed = this.randomIntFromInterval(0, 9999);
        }
        return this.sessionSeed;
    }

    randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }
}

