import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hour-info-item',
  templateUrl: './hour-info-item.component.html',
  styleUrls: ['./hour-info-item.component.css']
})
export class HourInfoItemComponent {
  @Input() openingHour
  @Input() closingHour
}
