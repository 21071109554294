import { Component, OnInit } from '@angular/core';
import {ImageManager} from 'src/app/shared/image-manager';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "../../../services/auth/auth.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  protected readonly ImageManager = ImageManager;

  flagIcon: string;

  constructor(
    private translation: TranslateService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.updateFlagIcon(this.translation.currentLang);
  }

  toggleLanguage(): void {
    const selectedLanguage = this.translation.currentLang === 'it' ? 'en' : 'it'
    this.changeLanguage(selectedLanguage)
  }

  changeLanguage(language: string): void {
    this.translation.use(language);
    this.updateFlagIcon(language);
    localStorage.setItem('language', language);
  }

  updateFlagIcon(currentLang: string): void {
    this.flagIcon = currentLang === 'it' ? ImageManager.italianFlagIcon : ImageManager.englishFlagIcon;
  }

  isUserFullyLogged() {
    return this.authService.isUserLogged();
  }
}
