<div class="position-absolute z-10 p-2 top-7 right-7" *ngIf="xploreItem.immersiveUrl">
  <svg class="w-12 h-12" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
       viewBox="0 0 24 24"
       (click)="goToImmersive()"
       xmlns="http://www.w3.org/2000/svg">
    <use [attr.xlink:href]="ImageManager.immersiveIcon"/>
  </svg>
</div>

<div class="w-100 h-auto position-absolute flex flex-row z-10 bottom-0 p-2">
  <div class="flex flex-column justify-around h-auto p-2 align-self-end" style="width: 75%">
    <div *ngIf="distanceFromUser">
      <p class="uppercase tracking-wide text-xl font-bold text-white drop-shadow-[5px_5px_5px_rgba(0,0,0,1)]">
        <span (click)="navigateToMap()"
              class="cursor-pointer uppercase tracking-wide text-l inline-block bg-gray-200 opacity-80 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          {{ distanceFromUser }}
        </span>
      </p>
    </div>
    <div *ngIf="hasOwner" class="flex flex-row py-1 gap-2 drop-shadow-[5px_5px_5px_rgba(0,0,0,1)]">
      <svg class="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd"
              d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
              fill-rule="evenodd"/>
      </svg>
      <p
        class="uppercase tracking-wide text-sm font-bold text-white truncate whitespace-nowrap">{{ownerName}}</p>
    </div>
    <p class="uppercase tracking-wide text-xl font-bold text-white drop-shadow-[5px_5px_5px_rgba(0,0,0,1)]">
      {{xploreItem.name}}
    </p>
    <div *ngIf="hasPriceRating">
      <p class="text-white text-md drop-shadow-[5px_5px_5px_rgba(0,0,0,1)]">{{priceRating}}</p>
    </div>
    <div class="pt-2">
      <span *ngFor="let tag of firstLevelTags" (click)="goToSearchTag(tag)"
            class="inline-block bg-gray-200 opacity-80 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 cursor-pointer">
        {{ 'tags.' + tag.code | translate  }}
      </span>
    </div>
  </div>
  <div class="flex flex-column justify-around h-auto p-2" style="width: 25%">
    <button (click)='share(xploreItem)' *ngIf="canShare()"
            class="text-white text-center font-bold mx-auto rounded mb-3">
      <svg class="w-8 h-8" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
           xmlns="http://www.w3.org/2000/svg">
        <use [attr.xlink:href]="ImageManager.shareIcon"/>
      </svg>
    </button>
    <button (click)="favoriteButtonClicked()"
            class="text-white text-center font-bold mx-auto rounded">
      <svg class="w-9 h-9 custom-base-color"
           [attr.fill]="checkIfItemIsFavorite() ? 'currentColor' : 'none'"
           [attr.stroke]="checkIfItemIsFavorite() ? 'none' : 'currentColor'"
           stroke-width="2"
           viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <use [attr.xlink:href]="ImageManager.bookmarkIcon"/>
      </svg>
    </button>

    <div class="w-100 d-flex justify-content-center">
      <button (click)="redirectToInfoPage()"
              class="custom-color w-fit text-center flex justify-center items-center font-bold px-3 py-2 mt-3 rounded-xl">
      <span *ngIf="!isEvent()">
        Info
      </span>
        <span *ngIf="isEvent()">
        {{'event.book-event-button' | translate}}
      </span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="this.xploreItem.multiMedia.length; else placeholder" class="h-100">
  <swiper [config]="imageSwiperConfig" class="h-100 w-100">
    <ng-template *ngFor="let image of xploreItem.multiMedia" style="width: 100vw; height: 100vh;" swiperSlide>
      <img [ngSrc]="getImage(image)" alt="" class="object-cover h-100 w-100"
           height="1920"
           width="1080">
      <div (click)="goToUserProfile(image.userAccount)" *ngIf="image.userAccount != null" class="position-absolute z-10 top-11 left-5 opacity-80">
        <div class="badge custom-color d-flex align-items-center" style="max-width: 150px;">
          <svg class="w-5 h-5 custom-base-color" fill="none" stroke="#ffffff" stroke-width="3"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use [attr.xlink:href]="ImageManager.cameraIcon"/>
          </svg>
          <span class="ms-1 w-100 text-ellipsis overflow-hidden d-inline-block whitespace-nowrap">{{image.userAccount.displayedName}}</span>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>
<ng-template #placeholder>
  <img [ngSrc]="ImageManager.getPlaceholderImage()"
       alt=""
       class="object-cover h-100 w-100"
       height="1920"
       width="1080">
</ng-template>

