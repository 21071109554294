<div class="flex flex-col p-4 border border-gray-400 shadow-md rounded-2xl">
  <div class="custom-background-color w-12 h-12 p-1 flex justify-center items-center rounded-lg">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clip-path="url(#clip0_467_735)">
        <path d="M22 13C22 12.7348 22.1054 12.4804 22.2929 12.2929C22.4804 12.1054 22.7348 12 23 12H25C25.2652 12 25.5196 12.1054 25.7071 12.2929C25.8946 12.4804 26 12.7348 26 13V15C26 15.2652 25.8946 15.5196 25.7071 15.7071C25.5196 15.8946 25.2652 16 25 16H23C22.7348 16 22.4804 15.8946 22.2929 15.7071C22.1054 15.5196 22 15.2652 22 15V13ZM16 13C16 12.7348 16.1054 12.4804 16.2929 12.2929C16.4804 12.1054 16.7348 12 17 12H19C19.2652 12 19.5196 12.1054 19.7071 12.2929C19.8946 12.4804 20 12.7348 20 13V15C20 15.2652 19.8946 15.5196 19.7071 15.7071C19.5196 15.8946 19.2652 16 19 16H17C16.7348 16 16.4804 15.8946 16.2929 15.7071C16.1054 15.5196 16 15.2652 16 15V13ZM6 19C6 18.7348 6.10536 18.4804 6.29289 18.2929C6.48043 18.1054 6.73478 18 7 18H9C9.26522 18 9.51957 18.1054 9.70711 18.2929C9.89464 18.4804 10 18.7348 10 19V21C10 21.2652 9.89464 21.5196 9.70711 21.7071C9.51957 21.8946 9.26522 22 9 22H7C6.73478 22 6.48043 21.8946 6.29289 21.7071C6.10536 21.5196 6 21.2652 6 21V19ZM12 19C12 18.7348 12.1054 18.4804 12.2929 18.2929C12.4804 18.1054 12.7348 18 13 18H15C15.2652 18 15.5196 18.1054 15.7071 18.2929C15.8946 18.4804 16 18.7348 16 19V21C16 21.2652 15.8946 21.5196 15.7071 21.7071C15.5196 21.8946 15.2652 22 15 22H13C12.7348 22 12.4804 21.8946 12.2929 21.7071C12.1054 21.5196 12 21.2652 12 21V19Z" fill="white"/>
        <path d="M7 0C7.26522 0 7.51957 0.105357 7.70711 0.292893C7.89464 0.48043 8 0.734784 8 1V2H24V1C24 0.734784 24.1054 0.48043 24.2929 0.292893C24.4804 0.105357 24.7348 0 25 0C25.2652 0 25.5196 0.105357 25.7071 0.292893C25.8946 0.48043 26 0.734784 26 1V2H28C29.0609 2 30.0783 2.42143 30.8284 3.17157C31.5786 3.92172 32 4.93913 32 6V28C32 29.0609 31.5786 30.0783 30.8284 30.8284C30.0783 31.5786 29.0609 32 28 32H4C2.93913 32 1.92172 31.5786 1.17157 30.8284C0.421427 30.0783 0 29.0609 0 28V6C0 4.93913 0.421427 3.92172 1.17157 3.17157C1.92172 2.42143 2.93913 2 4 2H6V1C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0ZM2 8V28C2 28.5304 2.21071 29.0391 2.58579 29.4142C2.96086 29.7893 3.46957 30 4 30H28C28.5304 30 29.0391 29.7893 29.4142 29.4142C29.7893 29.0391 30 28.5304 30 28V8H2Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_467_735">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
  <div class="grid grid-cols-2" *ngIf="isAnEvent">
    <div class="col-span-1">
      <dt class="mt-6 text-sm font-bold">{{ 'detail_modal.info_section.date' | translate }}</dt>
      <dd class="mt-2 text-sm">{{ this.startDate | date }}</dd>
    </div>
    <div class="col-span-1" *ngIf="closeReservationsDate">
      <dt class="mt-6 text-sm font-bold">{{'detail_modal.info_section.bookable_until' | translate}}</dt>
      <dd class="mt-2 text-sm">{{ this.closeReservationsDate | date }}</dd>
    </div>
  </div>
  <div class="grid grid-cols-2" *ngIf="!isAnEvent">
    <div class="col-span-1">
      <dt class="mt-6 text-sm font-bold">{{ 'detail_modal.info_section.start' | translate }}</dt>
      <dd class="mt-2 text-sm">{{ this.startDate | date }}</dd>
    </div>
    <div class="col-span-1">
      <dt class="mt-6 text-sm font-bold">{{'detail_modal.info_section.end' | translate}}</dt>
      <dd class="mt-2 text-sm">{{ this.endDate | date }}</dd>
    </div>
  </div>

</div>

