<div class="flex flex-col p-3 border border-gray-400 shadow-md rounded-2xl h-full">
  <div class="custom-background-color w-12 h-12 p-1 flex justify-center items-center rounded-lg">
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
      <g clip-path="url(#clip0_465_649)">
        <path opacity="0.8" d="M28.9601 20.5462C30.1476 21.0806 30.8007 22.3869 30.5039 23.6338L29.2571 29.215C28.9601 30.4619 27.8915 31.2931 26.5852 31.2931C12.0383 31.2931 0.222656 19.4774 0.222656 4.93062C0.222656 3.62436 1.05391 2.55561 2.30078 2.31811L7.88203 1.01187C9.12891 0.714991 10.4352 1.36812 10.9695 2.55561L13.5227 8.61187C13.9977 9.68062 13.7008 10.9869 12.8102 11.7587L10.3758 13.7181C12.0977 16.8056 14.7102 19.4181 17.7977 21.14L19.8164 18.765C20.5289 17.815 21.8351 17.5181 22.9039 17.9338L28.9601 20.5462ZM27.3571 28.7994L28.6633 23.2181C28.782 22.8026 28.5446 22.4462 28.1883 22.2682L22.1915 19.715C21.8351 19.5963 21.479 19.6557 21.2414 19.9525L18.807 22.9213C18.5102 23.2774 18.0351 23.3963 17.679 23.1587C13.6414 21.1994 10.3164 17.8744 8.35702 13.8962C8.11954 13.4806 8.23829 13.0056 8.59453 12.7087L11.5633 10.2744C11.8602 10.0369 11.9195 9.68062 11.8008 9.32436L9.24765 3.32748C9.06954 3.03062 8.77266 2.79312 8.47578 2.79312C8.4164 2.79312 8.35702 2.85249 8.29766 2.85249L2.7164 4.15875C2.36016 4.21811 2.12266 4.51499 2.12266 4.93062C2.12266 18.4088 13.107 29.4525 26.5852 29.4525C27.0007 29.4525 27.2977 29.1557 27.3571 28.7994Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_465_649">
          <rect width="32" height="32" fill="white" transform="translate(0.222656 0.293121)"/>
        </clipPath>
      </defs>
    </svg>
  </div>
  <dt class="mt-6 text-sm font-bold">{{ 'detail_modal.info_section.phone' | translate }}</dt>
  <div class="col-span-2 my-auto">
    <dd class="mt-2 text-sm">{{phoneNumber}}</dd>
  </div>
</div>
