<div class="height-without-bar overflow-hidden">
  <div *ngIf="!loading; else loadSpin" class="h-100 md:h-auto flex flex-col">
    <div class="flex-none">
      <div class="flex align-content-center px-4 font-bold text-xl my-2">
        <button (click)="goBackToItemCard()" class="my-auto rounded-full border-black mr-2">
          <svg class="w-7 h-7" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 19.5L8.25 12l7.5-7.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        {{xploreItem.name}}
      </div>
    </div>
    <div class="flex-none px-4 pt-2">
        <span *ngFor="let tag of firstLevelTags" (click)="goToSearchTag(tag)"
              class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 cursor-pointer">
<!--          {{ 'tags.' + tag.code | translate }}-->
          {{ buildTagName(tag) }}
        </span>
    </div>
    <book-section *ngIf="isEvent()" [xploreItem]="xploreItem"></book-section>
    <div class="grow h-0">
      <app-info-box [xploreItem]="xploreItem" class="w-100 h-100 inline-block"></app-info-box>
    </div>
  </div>

  <ng-template #loadSpin>
    <div class="height-without-bar flex align-items-center justify-content-center">
      <div class="spinner-border custom-big-spinner" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
</div>

