import { Injectable } from '@angular/core';
import {NgNavigatorShareService} from "ng-navigator-share";
import {AnalyticsService} from "../analytics/analytics.service";

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor(
    private ngNavigatorShareService: NgNavigatorShareService,
    private analyticsService: AnalyticsService
  ) { }

  canShare(): boolean {
    return this.ngNavigatorShareService.canShare();
  }

  share(xploreId: number, title: string, text: string, url: string): void {

    this.analyticsService.addShareAction(xploreId);

    this.ngNavigatorShareService.share({
      title: title,
      text: text,
      url: url
    }).then( (response) => {})
      .catch( (error) => {});
  }

}
