import {Component, Input } from '@angular/core';

@Component({
  selector: 'app-phone-info-item',
  templateUrl: './phone-info-item.component.html',
  styleUrls: ['./phone-info-item.component.css']
})
export class PhoneInfoItemComponent {
  @Input() phoneNumber
}
