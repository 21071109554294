import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'xplorer-bowser-instagram-info-item',
  templateUrl: './instagram-info-item.component.html',
  styleUrls: ['./instagram-info-item.component.css']
})
export class InstagramInfoItemComponent implements OnInit {

  @Input()
  instagramLink: string;

  constructor() { }

  ngOnInit(): void {
  }

  openInstagram(): void {
    open(this.instagramLink, '_blank').focus();
  }

}
