import { Injectable } from '@angular/core';
import { UserAccountDTO } from "../../shared/object/xplorer-be-parsed-classes";
import { JwtUtils } from "../../shared/utils/JwtUtils";
import {AuthToken} from "../../shared/object/keycloak-classes";
import {environment} from "../../../environments/environment";

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const REFRESH_TOKEN = 'refresh-token';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  signOut(): void {
    localStorage.clear();
  }

  public saveRefreshToken(token: string): void {
    localStorage.setItem(REFRESH_TOKEN, token);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  public saveToken(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: UserAccountDTO): void {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): UserAccountDTO {
    let item = localStorage.getItem(USER_KEY);
    if (item) {
      return JSON.parse(item);
    } else {
      return null;
    }
  }
  public removeUser = () => {
    localStorage.removeItem(USER_KEY);
  }

  public isTokenExpired(token) {

    if (!token || token === 'null' || token === 'undefined') {
      return true;
    }

    const arrayToken = token.split('.');
    const tokenPayload = JSON.parse(atob(arrayToken[1]));
    return Math.floor(new Date().getTime() / 1000) >= tokenPayload?.exp;
  }

  public getTokenPayload(token) {
    if(!token) {
      return null;
    }
    const arrayToken = token.split('.');
    return JSON.parse(atob(arrayToken[1]));
  }

  public getRolesFromToken(token) {
    const tokenPayload = this.getTokenPayload(token);
    let resourceAccess = tokenPayload?.resource_access[environment.KEYCLOAK_CLIENT_ID];
    return resourceAccess?.['roles'];
  }
}
