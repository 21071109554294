import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpParams} from "@angular/common/http";
import {AppSetting} from '../app.setting';
import {XploreItemDTO} from '../shared/object/xplorer-be-parsed-classes';
import {environment} from "../../environments/environment";
import {TerritoryService} from "./geolocation/territory.service";


@Injectable({
  providedIn: 'root',
})
export class XploreItemService {
  private readonly sessionSeed: number;

  constructor(private readonly http: HttpClient,
              private readonly appSetting: AppSetting,
              private readonly territoryService: TerritoryService) {
    this.sessionSeed = this.appSetting.getSessionSeed();
  }

  getXploreItemsFilteredByTagAndName(name: string, tags: string[], pageNumber: number): Observable<XploreItemDTO[]> {
    let params = this.buildStandardParams();

    if (tags.length > 0) {
      params = params.set('tags', tags.join(','));
    }

    if (name) {
      params = params.set('name', name);
    }

    params = params.set('pageNumber', pageNumber.toString());

    return this.http.get<XploreItemDTO[]>(`${environment.API_URL}/xplore/filter`, {params: params});
  }

  getPagedXploreItems(pageNumber: number): Observable<XploreItemDTO[]> {
    let params = this.buildStandardParams();
    return this.http.get<XploreItemDTO[]>(`${environment.API_URL}/xplore/paged/${pageNumber}/${this.sessionSeed}`, {params: params});
  }

  getXploreItems(): Observable<XploreItemDTO[]> {
    let params = this.buildStandardParams();
    return this.http.get<XploreItemDTO[]>(`${environment.API_URL}/xplore`, {params: params});
  }

  getXploreItemById(id: string | number): Observable<XploreItemDTO> {
    return this.http.get<XploreItemDTO>(`${environment.API_URL}/xplore/id/${id}`);
  }

  getNearbyXploreItems(xploreId: number): Observable<XploreItemDTO[]> {
    let params = this.buildStandardParams();
    return this.http.get<XploreItemDTO[]>(`${environment.API_URL}/xplore/nearby/${xploreId}`, {params: params});
  }

  getNearbyUserXploreItems(userPosition: GeolocationPosition): Observable<XploreItemDTO[]> {
    let params = this.buildStandardParams();
    return this.http.get<XploreItemDTO[]>(`${environment.API_URL}/xplore/nearby/${userPosition.coords.latitude}/${userPosition.coords.longitude}`, {params: params});
  }

  private buildStandardParams(noDraft: boolean = true): HttpParams {
    return new HttpParams()
      .set('territoryCode', this.territoryService.getTerritoryCodeFromUrl())
      .set('noDraft', noDraft);
  }
}
