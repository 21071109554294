import {Injectable} from "@angular/core";
import {Filter} from "../shared/object/filter";


@Injectable({
  providedIn: 'root'
})
export class FilterService {
  filtersId = ['accomodations', 'company', 'events', 'places_to_visit', 'restaurant', 'commercial'];
  filtersName = ['alloggio', 'azienda', 'evento', 'luogo_di_interesse', 'ristorante', 'servizio'];
  filterImageName = ['alloggi.jpg', 'aziende.jpg', 'evento.jpg', 'luoghi.jpg', 'ristoranti.jpg', 'servizi.jpg']

  private filters: Filter[] = [];
  private filtersStatus = new Map<string, boolean>();

  initFilters() {
    for (let i = 0; i < this.filtersId.length; i++) {
      this.filters.push(new Filter(this.filtersId[i], this.filtersName[i], this.filterImageName[i]));
      this.filtersStatus.set(this.filtersName[i], true);
    }
  }

  getFilters() {
    return this.filters;
  }

  getActiveFiltersNumber(): number {
    let activeFilters = 0
    this.filtersStatus.forEach((value, key) => {
      if (value === true) activeFilters++
    })
    return activeFilters
  }

  getFilterStatus(APIname: string): boolean {
    return this.filtersStatus.get(APIname);
  };

  checkIfNotLastActiveFilter(APIname: string): boolean {
    return (this.getActiveFiltersNumber() !== 1) || !this.getFilterStatus(APIname)
  }

  changeFilterStatus(APIname: string) {
    this.filtersStatus.set(APIname, !this.filtersStatus.get(APIname));
    return this.filtersStatus.get(APIname)
  }

  getActiveFilters(): string[] {
    let activeFilters: string[] = [];
    this.filtersStatus.forEach((value: boolean, key: string) => {
      if (value) activeFilters.push(key);
    });
    return activeFilters;
  }

  deActivateFilter(tagToDeactivate: string) {
    this.filtersStatus.set(tagToDeactivate, false);
  }

  deactivateAllFilters() {
    this.filtersStatus.forEach((value, key) => {
      this.filtersStatus.set(key, false);
    });
  }

  activateFilter(tagToActivate: string) {
    this.deactivateAllFilters();
    this.filtersStatus.set(tagToActivate, true);
  }
}
