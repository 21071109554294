<div class="flex items-center">
  <label class="sr-only" for="simple-search">Search</label>
  <div class="relative w-full">
    <input
      #inputName
      (focus)="emitEvent(true)"
      (focusout)="emitEvent(false)"
      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-red-800 block w-full p-2.5"
      id="simple-search"
      placeholder="Search..."
      required type="text">
  </div>
  <button
    (click)="buttonClicked()"
    class="custom-color p-2.5 ml-2 text-sm font-medium text-white rounded-lg border  focus:outline-none">
    <svg aria-hidden="true" class="w-4 h-4" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"/>
    </svg>
    <span class="sr-only">Search</span>
  </button>
</div>
