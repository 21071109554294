import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CommercialDTO,
  EventDTO,
  MultiMediaDTO, TagDTO, UserAccountDTO,
  XploreItemDTO
} from "../../../shared/object/xplorer-be-parsed-classes";
import { SwiperOptions } from "swiper";
import { Router } from '@angular/router';
import { UserService } from "../../../services/user/user.service";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../services/auth/auth.service";
import { ShareService } from "../../../services/share/share.service";
import { UserGeolocationService } from "../../../services/geolocation/user-geolocation.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {ImageManager} from "../../../shared/image-manager";
import {TerritoryService} from "../../../services/geolocation/territory.service";
import {AnalyticsService} from "../../../services/analytics/analytics.service";

@Component({
  selector: 'app-mobile-item-card',
  templateUrl: './mobile-item-card.component.html',
  styleUrls: ['./mobile-item-card.component.css']
})
export class MobileItemCardComponent implements OnInit, OnDestroy {

  @Input() xploreItem: XploreItemDTO;

  public imageSwiperConfig: SwiperOptions = {
    slidesPerView: 1,
    preloadImages: true,
    spaceBetween: 0,
    pagination: true,
    resistanceRatio: 0,
    navigation: true,
    autoHeight: true
  };

  distanceFromUser: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private shareService: ShareService,
    private userGeolocationService: UserGeolocationService
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleGeolocation();
  }

  getImage(image: MultiMediaDTO) {
    return image?.mediaURL ? environment.IMAGES_URL  + image.mediaURL : ImageManager.getPlaceholderImage();
  }

  redirectToInfoPage() {
    this.router.navigate(['explore/info/' + this.xploreItem.id]);
  }

  get firstLevelTags() {
    return this.xploreItem.tags.filter(tag => tag.tagLevel == 0)
  }

  favoriteButtonClicked() {
    this.authService.isUserLogged().subscribe(isUserLogged => {
      if (!isUserLogged) {
        this.router.navigate(['login']);
      } else {
        if (!this.userService.checkIfItemInFavorite(this.xploreItem.id)) {
          this.userService.addSavedXplore(this.xploreItem.id);
        } else {
          this.userService.removeFavorite(this.xploreItem.id);
        }
      }
    });
  }

  checkIfItemIsFavorite() {
    return this.userService.checkIfItemInFavorite(this.xploreItem.id)
  }

  get hasOwner() {
    if (this.isEvent()) {
      let event = this.xploreItem as EventDTO
      return event.companyDto !== null
    } else if(this.isCommercial()) {
      let commercial = this.xploreItem as CommercialDTO
      return commercial.company !== null
    }
    return false;
  }

  get ownerName() {
    if (this.isCommercial()) {
      let commercial = this.xploreItem as CommercialDTO
      return commercial.company !== null ? commercial.company.name : ''
    } else if(this.isEvent()) {
      let event = this.xploreItem as EventDTO
      return event.companyDto !== null ? event.companyDto.name : ''
    }
    return ''
  }

  get hasPriceRating() {
    if (this.xploreItem.itemType === 'COMMERCIAL') {
      let commercial = this.xploreItem as CommercialDTO
      return commercial.priceRating !== null
    } else if (this.xploreItem.itemType === 'EVENT') {
      let event = this.xploreItem as EventDTO
      return event.priceRating !== null
    }
    return false
  }

  get priceRating() {
    if(this.xploreItem.itemType === 'EVENT') {
      let event = this.xploreItem as EventDTO
      return "€".repeat(event.priceRating)
    } else if(this.xploreItem.itemType === 'COMMERCIAL') {
      let commercial = this.xploreItem as CommercialDTO
      return "€".repeat(commercial.priceRating)
    }
    return null;
  }

  canShare(): boolean {
    return this.shareService.canShare();
  }

  share(xploreItem: XploreItemDTO): void {
    const title = xploreItem.name;
    const text = xploreItem.shortDescription;
    const url = environment.DEFAULT_ITEM_URL + xploreItem.id;

    this.shareService.share(xploreItem.id, title, text,
      TerritoryService.addTerritoryCodeToUrl(environment.DEFAULT_ITEM_URL + xploreItem.id, xploreItem.territory.code)
    );
  }

  isEvent() {
    return this.xploreItem.itemType === 'EVENT';
  }

  isCommercial() {
    return this.xploreItem.itemType === 'COMMERCIAL';
  }

  private handleGeolocation() {
    this.distanceFromUser = this.userGeolocationService.getDistanceXploreItemFromUserLocation(this.xploreItem) + ' km';
  }

  buildTagName(tag: TagDTO) {
    let tagName = tag.code;
    tagName = tagName.replace(/_/g, ' ');
    tagName = tagName.charAt(0).toUpperCase() + tagName.slice(1);
    return tagName;
  }

  navigateToMap() {
    this.router.navigate(['map/' + this.xploreItem.id]);
  }

  goToImmersive() {
    this.router.navigate(['immersive/' + this.xploreItem.id]);
  }

  goToSearchTag(tag: TagDTO) {
    this.router.navigate(['search/' + tag.code]);
  }

  protected readonly ImageManager = ImageManager;

  goToUserProfile(userAccount: UserAccountDTO) {
    this.router.navigate(['profile/' + userAccount.userId]);
  }
}
