import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {TerritoryService} from "./geolocation/territory.service";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private territoryService: TerritoryService) { }

  public changeTheme() {
    let territoryCodeFromUrl = this.territoryService.getTerritoryCodeFromUrl();

    if (environment.IS_LOCAL) {
      territoryCodeFromUrl = "mugello";
    }

    let territoryStyles = document.getElementById("territory-styles");
    territoryStyles.setAttribute("href", "/assets/xplorer/" + territoryCodeFromUrl + "/styles.css");
  }

  public changeThemeByTerritory(territoryCode: string) {
    let territoryStyles = document.getElementById("territory-styles");
    territoryStyles.setAttribute("href", "/assets/xplorer/" + territoryCode + "/styles.css");
  }

}
