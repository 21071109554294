import {Component, Input } from '@angular/core';

@Component({
  selector: 'app-email-info-item',
  templateUrl: './email-info-item.component.html',
  styleUrls: ['./email-info-item.component.css']
})
export class EmailInfoItemComponent {
  @Input() email
}
