import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-price-info-item',
  templateUrl: './price-info-item.component.html',
  styleUrls: ['./price-info-item.component.css']
})
export class PriceInfoItemComponent implements OnInit {
  @Input() price

  constructor() {
  }

  ngOnInit(): void {
  }
}
