import { Injectable } from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {XploreItemDTO} from "../../shared/object/xplorer-be-parsed-classes";
import {ProductLabelDTO} from "../../shared/object/xplorer-label-parsed-classes";
import {TerritoryService} from "../geolocation/territory.service";

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {

  private DEFAULT_KEYWORDS = 'Xplorer, ' +
    'Xplorer Bowser, Xplorer Bowser 2.0, Xplorer Bowser 2.0.0, Xplorer Bowser, Xplorer Chianti,' +
    ' Explore, Vacation, Chianti, Tuscany, Italy, Florence' ;

  private DEFAULT_DESCRIPTION = 'Come and explore the Chianti region with us!';

  private DEFAULT_TITLE = 'Xplorer ';

  constructor(
    private metaTagService: Meta,
    private titleService: Title,
    private territoryService: TerritoryService
  ) { }

  public setDefaultMetaData() {
    this.updateTitle(this.DEFAULT_TITLE + this.titleCase(this.territoryService.getTerritoryCodeFromUrl()));
    this.updateDescription(this.DEFAULT_DESCRIPTION);
    this.updateKeywords(this.DEFAULT_KEYWORDS, true);
  }

  public updateMetaDataByXploreItem (xploreItem: XploreItemDTO) {
    if (xploreItem) {
      this.updateTitle(xploreItem.name);
      this.updateDescription(xploreItem.shortDescription);
      let keywords: string = xploreItem.tags.map(tag => tag.code).join(',');
      keywords += ' ' + xploreItem.name;
      this.updateKeywords(keywords);
    } else {
      this.setDefaultMetaData();
    }
  }

  public updateMetaDataByProductLabel (productLabelDTO: ProductLabelDTO) {
    if (productLabelDTO) {
      this.updateTitle(productLabelDTO.productProperty.productName);
      this.updateDescription(productLabelDTO.productProperty.localizations[0].productDescription);
    } else {
      this.setDefaultMetaData();
    }
  }

  private updateTitle(title: string) {
    this.titleService.setTitle(title);
    this.updateOgTitle(title);
  }

  private updateOgTitle(title: string) {
    this.metaTagService.updateTag({ property: 'og:title', content: title });
  }

  private updateDescription(description: string) {
    this.metaTagService.updateTag({ name: 'description', content: description });
    this.updateOgDescription(description);
  }

  private updateOgDescription(description: string) {
    this.metaTagService.updateTag({ property: 'og:description', content: description });
  }

  private updateKeywords(keywords: string, override: boolean = false) {
    if (override) {
      this.metaTagService.updateTag({ name: 'keywords', content: keywords});
      this.updateOgKeywords(keywords);
    } else {
      this.metaTagService.updateTag({ name: 'keywords', content: this.DEFAULT_KEYWORDS + ',' + keywords});
      this.updateOgKeywords(this.DEFAULT_KEYWORDS + ',' + keywords);
    }

  }

  private updateOgKeywords(keywords: string) {
    this.metaTagService.updateTag({ property: 'og:keywords', content: keywords });
  }

  private titleCase(str: string): string {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    return '';
  }
}
