import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import {debounceTime, fromEvent, merge, of, Subscription, take, tap, timer} from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[longPress]',
})
export class LongPressDirective implements OnDestroy {
  private eventSubscribe: Subscription;
  threshold = 250;

  @Output()
  longPressEmitter = new EventEmitter();

  constructor(private elementRef: ElementRef) {

    const mousedown = fromEvent<MouseEvent>(elementRef.nativeElement, 'mousedown').pipe(
      filter((event) => event.button == 0), // Only allow left button (Primary button)
      map((event) => true) // turn on threshold counter
    );

    const touchstart = fromEvent(elementRef.nativeElement, 'touchstart').pipe(
      map(() => true)
    );

    const touchEnd = fromEvent(elementRef.nativeElement, 'touchend').pipe(
      map(() => false)
    );

    const mouseup = fromEvent<MouseEvent>(window, 'mouseup').pipe(
      filter((event) => event.button == 0), // Only allow left button (Primary button)
      map(() => false) // reset threshold counter
    );

    this.eventSubscribe = merge(mousedown, mouseup, touchstart, touchEnd)
      .pipe(
        switchMap((state) => {
          if (state) {
            // Start the timer if the state is true
            return timer(this.threshold, 100).pipe(
              take(1), // Emit once and complete
              map(() => true)
            );
          } else {
            // Emit false immediately if the state is false
            return of(false);
          }
        }),
        filter((value) => value === true), // Filter out false values
        tap(() => this.longPressEmitter.emit()) // Emit the long press event
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.eventSubscribe) {
      this.eventSubscribe.unsubscribe();
    }
  }
}
