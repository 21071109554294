export enum ToastType {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info"
}

export enum ToastTextType {
  ADDED_TO_FAVORITES = "added_to_favorites",
  REMOVED_FROM_FAVORITES = "removed_from_favorites",
  LOGIN_SUCCESSFUL = "login_successful"
}
