import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TerritoryService {

  constructor() { }

  getTerritoryCodeFromUrl(): string {
    let getLink =  window.location.href;

    if(environment.IS_LOCAL) {
      getLink = "https://mugello.xplorer-platform.dev/explore";
    }

    let subdomain = getLink.split(".")[0];
    return subdomain.split("//")[1];
  }

  static addTerritoryCodeToUrl(originalUrl: string, newSubdomain: string): string {
    const parts = originalUrl.split('://');
    if (parts.length === 2) {
      const [protocol, domain] = parts;
      return `${protocol}://${newSubdomain}.${domain}`;
    } else {
      throw new Error('Invalid URL format');
    }
  }
}
