import {AfterViewInit, ChangeDetectionStrategy, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {XploreItemDTO} from 'src/app/shared/object/xplorer-be-parsed-classes';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {XploreItemService} from 'src/app/services/xplore-item.service';
import {FilterService} from 'src/app/services/filter.service';
import {Filter} from 'src/app/shared/object/filter';
import {NgbAccordion} from "@ng-bootstrap/ng-bootstrap";
import {MetaDataService} from "../../../services/meta/meta-data.service";
import {SearchInputComponent} from "./search-input/search-input.component";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.css'],
})
export class SearchPageComponent implements OnInit {

  @ViewChild('filterAccordion') accordionComponent: NgbAccordion;
  @ViewChild(SearchInputComponent) searchInput: SearchInputComponent;

  // Aggiungere l input per ricevere un tag da cercare

  filters: Filter[] = [];
  showingItems: XploreItemDTO[] = [];
  loading = false;
  errorHappened: boolean = false;
  loadedIndex = 0;
  isScrollActive: Boolean = true;
  isUsingSearchBar = false;
  desktop: boolean
  private onDestroy$: Subject<void> = new Subject<void>();
  private mainServiceSubscription$: Subscription;

  constructor(
    private filterService: FilterService,
    private xploreItemService: XploreItemService,
    private metaDataService: MetaDataService,
    private route: ActivatedRoute
  ) {
    this.filters = filterService.getFilters();
    this.desktop = window.innerWidth > 640
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.desktop = window.innerWidth > 640
  }

  ngOnInit(): void {
    this.metaDataService.setDefaultMetaData();

    const tagToSearch = this.route.snapshot.paramMap.get('tag');
    if (tagToSearch) {
      this.filterService.activateFilter(tagToSearch);
      console.debug(this.filterService.getActiveFilters())
      this.getXploreItems(null, [tagToSearch], this.loadedIndex);
    } else {
      this.getXploreItems(null, this.filterService.getActiveFilters(), this.loadedIndex);
    }
  }

  getXploreItems(name: string, tags: string[], index: number) {
    this.loading = true;
    this.xploreItemService.getXploreItemsFilteredByTagAndName(name, tags, index)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: xploreItems => {
          this.loading = false;
          this.showingItems = xploreItems;
          // this.loadedIndex++;
        },
        error: _ => {
          this.loading = false
          this.errorHappened = true
        }
      })
  }

  updateItems = (): void => {
    this.getXploreItems(this.searchInput.getName(), this.filterService.getActiveFilters(), this.loadedIndex)
  }

  onWindowScroll(event) {
    if (this.accordionComponent.isExpanded('filters')) {
      this.accordionComponent.toggle('filters')
    }
  }

  toggleAccordion(event) {
    this.isScrollActive = false
    this.accordionComponent.toggle('filters')
    setTimeout(() => {
      this.isScrollActive = true;
    }, 500)
  }


  onScroll() {
    this.mainServiceSubscription$ = this.xploreItemService.getPagedXploreItems(this.loadedIndex)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: xploreItems => {
          if (xploreItems) {
            this.showingItems = [...this.showingItems, ...xploreItems];
            this.loadedIndex++;
          }
        },
        error: _ => {
          this.errorHappened = true
          this.loading = false
        }
      })
  }

}
