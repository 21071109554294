import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from "../auth/token-storage.service";
import { environment } from "../../../environments/environment";

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing: boolean;

  constructor(
    private tokenStorage: TokenStorageService,
    private readonly router: Router
  ) {
    this.isRefreshing = false;
  }

  intercept = (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> => {

    if(environment.UNSECURE) {
      return next.handle(request);
    }

    const token = this.tokenStorage.getToken();

    if (token) {
      if(this.tokenStorage.isTokenExpired(token)) {
        this.tokenStorage.signOut();
      } else if (request.url !== environment.KEYCLOAK_TOKEN_URL) {
        request = request.clone({
          headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`)
        });
      }
    }

    return next.handle(request);
  }
}
