import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-website-info-item',
  templateUrl: './website-info-item.component.html',
  styleUrls: ['./website-info-item.component.css']
})
export class WebsiteInfoItemComponent {
  @Input() website
}
