import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {EventReservationRequestDTO, XploreItemDTO} from "../../../shared/object/xplorer-be-parsed-classes";
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ReservationService} from "../../../services/reservation.service";
import {AuthService} from "../../../services/auth/auth.service";
import {ToastService} from "../../../services/toast/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'xplorer-bowser-reservation-popup',
  templateUrl: './reservation-popup.component.html',
  styleUrls: ['./reservation-popup.component.css']
})
export class ReservationPopupComponent implements OnInit, OnChanges{

  @ViewChild('popup') modal: NgbActiveModal;

  @Input()
  alreadyReserved: boolean;

  @Input()
  xploreItem: XploreItemDTO;

  @Input()
  toggle: boolean;

  @Output()
  reservationConfirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  private ngbModalRef: NgbModalRef;

  isLoading: boolean = false;

  eventReservationRequestDTO: EventReservationRequestDTO = undefined;

  reservationFormGroup: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    phoneNumber: new FormControl(''),
    additionalNumberOfPeople: new FormControl(0),
    additionalInfo: new FormControl('')
  });

  constructor(
    private modalService: NgbModal,
    public reservationService: ReservationService,
    public authService: AuthService,
    public toastService: ToastService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void { }

  openModal() {
    this.ngbModalRef = this.modalService.open(this.modal, {size: 'lg'} as NgbModalOptions);
    this.isLoading = false;
  }

  closeModal() {
    this.ngbModalRef.close(false)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['toggle'] && !changes['toggle'].firstChange){
      this.openModal();
    }
  }

  confirmReservation() {
    this.isLoading = true;
    this.reservationService.reserveEvent(this.eventReservationRequestDTO)
      .pipe()
      .subscribe({
        next: eventReservationDTO => {
          console.debug(eventReservationDTO);
          this.isLoading = false;
          this.toastService.showCustomSuccessToast(this.translate.instant('event.reservation-confirmed'));
          this.ngbModalRef.close(true);
          this.reservationConfirmed.emit(true);
        },
        error: error => {
          this.isLoading = false;
          console.error(error);
          this.toastService.showErrorToast(this.translate.instant('event.error-registering'));
          this.ngbModalRef.close();
        }
      });
  }

  deleteReservation() {
    this.isLoading = true;
    this.reservationService.deleteReservation(this.xploreItem.id)
      .pipe()
      .subscribe({
        next: _ => {
          this.ngbModalRef.close(true)
          this.reservationConfirmed.emit(true);
        },
        error: error => {
          this.toastService.showErrorToast(this.translate.instant(''));
          this.ngbModalRef.close(false)
        }
      });
  }

  submitReservation(reservationFormGroup: FormGroup) {

    let userId = this.authService.getUser()?.userId;

    this.eventReservationRequestDTO = {
      name: reservationFormGroup.controls['name'].value,
      surname: reservationFormGroup.controls['surname'].value,
      phoneNumber: reservationFormGroup.controls['phoneNumber'].value,
      additionalNumberOfPeople: reservationFormGroup.controls['additionalNumberOfPeople'].value,
      additionalInfo: reservationFormGroup.controls['additionalInfo'].value,
      eventId: this.xploreItem.id,
      userId: userId,
    }

    this.confirmReservation();
  }

  shouldShowError(fieldName: string) {
    return this.reservationFormGroup.get(fieldName).invalid
      && (this.reservationFormGroup.get(fieldName).dirty || this.reservationFormGroup.get(fieldName).touched)
  }
}
