import {Injectable} from '@angular/core';
import {catchError, EMPTY, map, Observable, ObservableInput} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {EventReservationDTO, EventReservationRequestDTO, ExploreDTO} from '../shared/object/xplorer-be-parsed-classes';
import {environment} from 'src/environments/environment';
import {AuthService} from "./auth/auth.service";
import {takeUntil} from "rxjs/operators";


@Injectable({
  providedIn: 'root',
})
export class ReservationService {

  constructor(
    private readonly http: HttpClient
  ) {
  }

  reserveEvent(eventReservationRequestDTO: EventReservationRequestDTO): Observable<EventReservationDTO> {
    return this.http.post<EventReservationDTO>(`${environment.API_URL}/reservations`, eventReservationRequestDTO);
  }

  deleteReservation(eventId): Observable<number> {
    return this.http.delete<number>(`${environment.API_URL}/reservations/event/${eventId}`);
  }

  getReservationByUser(): Observable<EventReservationDTO[]> {
    return this.http.get<EventReservationDTO[]>(`${environment.API_URL}/reservations/user`);
  }

  getAvailableReservations(eventId): Observable<number> {
    return this.http.get<number>(`${environment.API_URL}/reservations/event/${eventId}/available`);
  }
}
