import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {XploreItemService} from "../../../services/xplore-item.service";
import {XploreItemDTO} from "../../../shared/object/xplorer-be-parsed-classes";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-related-items-manager',
  templateUrl: './related-items-manager.component.html',
  styleUrls: ['./related-items-manager.component.css']
})
export class RelatedItemsManagerComponent implements OnChanges, OnDestroy {

  @Input()
  xploreItemId: number;

  @Input()
  isShowed: boolean;

  relatedXploreItems: XploreItemDTO[] = [];
  destroyed$ = new Subject<void>();

  isLoadingRelatedItems = false;

  constructor(
    private xploreItemService: XploreItemService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['isShowed'].currentValue && this.relatedXploreItems.length === 0) {
      this.isLoadingRelatedItems = true;
      this.xploreItemService.getNearbyXploreItems(this.xploreItemId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
        (response) => {
          this.isLoadingRelatedItems = false;
          this.relatedXploreItems = response;
        }
      )
    }
  }


  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
