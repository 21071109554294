<a class="flex flex-col p-3 border border-gray-400 shadow-md rounded-2xl h-full" href="{{website}}" target="_blank">
  <div class="custom-background-color w-12 h-12 p-1 flex justify-center items-center rounded-lg">
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <path d="M17.2227 33.2931C26.0592 33.2931 33.2227 26.1297 33.2227 17.2931C33.2227 8.45656 26.0592 1.29311 17.2227 1.29311C8.3861 1.29311 1.22266 8.45656 1.22266 17.2931C1.22266 26.1297 8.3861 33.2931 17.2227 33.2931Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.13867 11.9598H32.3053" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.13867 22.6264H32.3053" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.2233 32.8598C20.9052 32.8598 23.89 25.8903 23.89 17.2931C23.89 8.69588 20.9052 1.72645 17.2233 1.72645C13.5414 1.72645 10.5566 8.69588 10.5566 17.2931C10.5566 25.8903 13.5414 32.8598 17.2233 32.8598Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  <dt class="mt-6 text-sm font-bold">{{ 'detail_modal.info_section.website' | translate }}</dt>
    <div class="col-span-2 my-auto">
      <dd class="mt-2 text-sm break-words">{{website}}</dd>
    </div>
</a>
