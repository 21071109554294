<a href="explore/{{this.xploreItem.id}}">
  <div class="max-w-sm rounded-lg overflow-hidden shadow-sm">
    <div class="w-full h-60vh xl:h-80vh overflow-hidden">
      <img [src]="getImage()"
           class="object-scale-down object-center"
           alt="Xplore Placeholder">
    </div>
    <div class="p-1 h-auto">
      <p class="font-bold text-xs title my-1 text-center">{{xploreItem.name}}</p>
      <div class="flex flex-row justify-content-center" *ngIf="xploreItem.geoPositionDTO?.comune">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="3 1 25 25"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
        <p class="text-xs truncate">{{xploreItem.geoPositionDTO.comune.name}}</p>
      </div>
    </div>
  </div>
</a>

<!--<a href="explore/{{this.xploreItem.id}}">-->
<!--  <div class="max-w-sm rounded-lg overflow-hidden shadow-sm">-->
<!--    <div class="w-full h-60vh xl:h-80vh overflow-hidden">-->
<!--      <img [src]="getImage()"-->
<!--           class="object-scale-down object-center"-->
<!--           alt="Xplore Placeholder">-->
<!--    </div>-->
<!--    <div class="p-1 h-auto">-->
<!--      <p class="font-bold text-xs">{{xploreItem.name}}</p>-->
<!--      <div class="flex flex-row" *ngIf="xploreItem.geoPositionDTO?.comune">-->
<!--        <svg class="w-4 h-4" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="3 1 25 25"-->
<!--             xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" stroke-linecap="round"-->
<!--                stroke-linejoin="round"/>-->
<!--        </svg>-->
<!--        <p class="text-xs my-auto truncate">{{xploreItem.geoPositionDTO.comune.name}}</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</a>-->
