<div class="hidden sm:block h-16 top-0 z-50 w-100">
  <nav class="bg-white h-100">
    <div class="mx-auto h-100 px-4 sm:px-6 lg:px-14 align-items-center d-flex">
      <div class="row my-0">
        <div class="col-5 flex items-center">
          <div class="hidden sm:block">
            <div class="flex items-baseline space-x-4">
              <a
                class="full-button rounded-md text-sm font-medium custom-button-inactive"
                routerLink="/explore"
                routerLinkActive="header-color disable-hover">
                Home
              </a>
              <a
                class="small-button rounded-md  text-sm font-medium custom-button-inactive"
                routerLink="/explore"
                routerLinkActive="header-color disable-hover">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="1.5"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"/>
                </svg>
              </a>
              <a
                class="full-button rounded-md text-sm font-medium custom-button-inactive"
                routerLink="/search"
                routerLinkActive="header-color disable-hover">
                {{'tabs.search' | translate}}
              </a>
              <a
                class="small-button rounded-md text-sm font-medium custom-button-inactive" routerLink="/search"
                routerLinkActive="header-color disable-hover">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                     stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" x2="16.65" y1="21" y2="16.65"></line>
                </svg>
              </a>
              <a
                class="full-button rounded-md text-sm font-medium custom-button-inactive"
                routerLink="/favorites"
                routerLinkActive="header-color disable-hover">
                {{'tabs.favorites' | translate}}
              </a>
              <a
                class="small-button rounded-md text-sm font-medium custom-button-inactive"
                routerLink="/favorites"
                routerLinkActive="header-color disable-hover">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="1.5"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                    stroke-linecap="round"
                    stroke-linejoin="round"/>
                </svg>
              </a>
              <a
                class="full-button rounded-md text-sm font-medium custom-button-inactive"
                routerLink="/map"
                routerLinkActive="header-color disable-hover">
                {{'tabs.map' | translate}}
              </a>
              <a
                class="small-button rounded-md text-sm font-medium custom-button-inactive"
                routerLink="/map"
                routerLinkActive="header-color disable-hover">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="1.5"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                    stroke-linecap="round"
                    stroke-linejoin="round"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="col-2 flex align-items-center justify-content-center">
          <div class="mt-3 flex cursor-pointer justify-content-center align-items-center"  style="width: 60%; min-width: 140px" routerLink="/explore">
            <img alt="Xplorer" class="w-100" [src]="ImageManager.getLogoXplorerFull()">
          </div>
        </div>
        <div class="col-5 flex items-center justify-content-end">
          <button (click)="toggleLanguage()" class="mr-2 h-10 w-10 rounded-full hover:bg-gray-200">
            <div class="h-7 w-7 m-auto rounded-full overflow-hidden" style="border: solid 1px">
              <img [src]="flagIcon" alt="language flag" class="h-100 object-cover">
            </div>
          </button>
          <a class="ml-2 h-10 w-10 rounded-full hover:bg-gray-200" routerLink="/profile">
            <svg *ngIf="!isUserFullyLogged()"
                 class="m-1" className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth={1.5}
                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                strokeLinecap="round"
                strokeLinejoin="round"/>
            </svg>
            <svg *ngIf="isUserFullyLogged()"
                 class="m-1" className="w-6 h-6" fill="currentColor"
                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd"
                    d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                    fill-rule="evenodd"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </nav>
</div>

