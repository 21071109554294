import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.css']
})
export class SearchInputComponent implements OnInit {

  @Output() isSearchingEmitter = new EventEmitter();
  @ViewChild('inputName') input: ElementRef;
  @Output('updateItems') updateItems: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  getName() {
    return this.input.nativeElement.value
  }

  buttonClicked() {
    this.updateItems.emit()
  }


  emitEvent(event) {
    this.isSearchingEmitter.emit(event)
  }
}
