import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from "../auth/auth.service";
import { map, Observable } from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router) {
    }

    canActivate = (route: ActivatedRouteSnapshot): Observable<boolean> => {

        if(environment.UNSECURE) {
          return new Observable<boolean>(observer => {
            observer.next(true);
            observer.complete();
          });
        }

        return this.authService.isUserLogged()
            .pipe(
                map((isUserLogged: boolean) => {
                    if (!isUserLogged && route.routeConfig.path === 'login') {
                        return true;
                    }

                    if (isUserLogged) {
                        if (route.routeConfig.path === 'login') {
                            this.router.navigate(['profile']);
                        }
                        return true;
                    }

                    const redirectUrl = this.buildRedirectUrl(route)
                    this.router.navigate(['login'], {state: {source: redirectUrl}})
                    return false;
                })
            );
    }

    private buildRedirectUrl = (route: ActivatedRouteSnapshot): string => {
        let redirectUrl;
        route.url.forEach(url =>
            redirectUrl = redirectUrl ? `${redirectUrl}/${url.path}` : url.path
        );
        return redirectUrl;
    }
}
