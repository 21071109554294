<div class="card-box grid grid-cols-2 divide-x">
  <div class="image-box flex-none rounded-l bg-cover">
    <div *ngIf="xploreItem.multiMedia.length === 0">
      <img [ngSrc]="ImageManager.getPlaceholderImage()"
           alt=""
           class="relative h-100"
           height="1920"
           width="1080">
    </div>
    <div class="position-relative w-fit cursor-pointer rotate-vert-center" style="top: 4%; left: 83%; z-index: 100; width: 0; height: 0" *ngIf="xploreItem.immersiveUrl">
      <svg class="w-12 h-12" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24"
           (click)="goToImmersive()"
           xmlns="http://www.w3.org/2000/svg">
        <use [attr.xlink:href]="ImageManager.immersiveIcon"/>
      </svg>
    </div>
    <swiper *ngIf="xploreItem.multiMedia.length > 0"
            [config]="imageSwiperConfig" id="selectionSection">
      <ng-template *ngFor="let image of xploreItem.multiMedia" swiperSlide>
        <div>
          <img [ngSrc]="getImage(image)"
               alt="!! Questo significa che l'xplore ha una immagine che non riesce peró ad essere caricata"
               class="aspect-[9/16] img-fluid" height="1920" width="1080">
          <div *ngIf="image.userAccount != null" class="position-absolute z-10 bottom-5 left-5 cursor-pointer" (click)="goToUserProfile(image.userAccount)">
            <div class="badge custom-color d-flex align-items-center" style="max-width: 150px;">
              <svg class="w-5 h-5 custom-base-color" fill="none" stroke="#ffffff" stroke-width="3"
                   viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <use [attr.xlink:href]="ImageManager.cameraIcon"/>
              </svg>
              <span class="ms-1 w-100 text-ellipsis overflow-hidden d-inline-block whitespace-nowrap">{{image.userAccount.displayedName}}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
  <div class="flex flex-column overflow-hidden">
    <div class="flex-none">
      <div class="px-6 pt-4">
				<span *ngFor="let tag of getFirstLevelTags()" (click)="goToSearchTag(tag)"
              class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold
              text-gray-700 mr-2 mb-2 cursor-pointer tag-button">
          {{ 'tags.' + tag.code | translate  }}
        </span>
      </div>
      <div class="mx-8 my-2">
        <div *ngIf="hasOwner()" class="flex flex-row py-1 gap-2">
          <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd"
                  d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                  fill-rule="evenodd"/>
          </svg>
          <p
            class="text-sm font-bold truncate whitespace-nowrap">{{getOwnerName()}}</p>
        </div>
        <div class="flex justify-between">
          <div class="text-gray-900 font-bold text-lg">{{xploreItem.name}}</div>
          <div>
            <button (click)="favoriteButtonClicked()"
                    class="text-red-500 text-center font-bold rounded mr-1">
              <svg class="w-7 h-7 custom-base-color"
                   [attr.fill]="checkIfItemIsFavorite() ? 'currentColor' : 'none'"
                   [attr.stroke]="checkIfItemIsFavorite() ? 'none' : 'currentColor'"
                   stroke-width="2"
                   viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <use [attr.xlink:href]="ImageManager.bookmarkIcon"/>
              </svg>
            </button>

            <button (click)='share(xploreItem)' *ngIf="canShare()"
                    class="text-red-500 text-center font-bold rounded ml-1">
              <svg class="w-7 h-7 custom-base-color" fill="none" stroke="currentColor" stroke-width="2"
                   viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <use [attr.xlink:href]="ImageManager.shareIcon"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <book-section *ngIf="isEvent()" [xploreItem]="xploreItem"></book-section>
    <div class="grow overflow-hidden">
      <app-info-box [xploreItem]="xploreItem"></app-info-box>
    </div>
  </div>
</div>

