import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../services/auth/auth.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { UserAccountDTO } from "../../../shared/object/xplorer-be-parsed-classes";
import { TokenStorageService } from "../../../services/auth/token-storage.service";
import { KeycloakService } from "keycloak-angular";
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {UserService} from "../../../services/user/user.service";
import {from, map, mergeMap} from "rxjs";

@Component({
  selector: 'xplorer-bowser-load-user',
  templateUrl: './load-user.component.html',
  styleUrls: ['./load-user.component.css']
})
export class LoadUserComponent implements OnInit {

  loadingText: any;

  constructor(
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly tokenStorageService: TokenStorageService,
    private readonly keycloakService: KeycloakService,
    private readonly analyticsService: AnalyticsService,
    private readonly userService: UserService
  ) {
  }

  ngOnInit(): void {
    let sourcePath = this.route.snapshot.queryParams['source'];
    this.processUserInfo(sourcePath);
  }

  processUserInfo = (sourcePath: string) => {
    const redirectUrl = sourcePath ? sourcePath : 'profile';

    this.keycloakService.getToken().then(token => {
      console.debug('Token', token);
      this.tokenStorageService.saveToken(token);
    }).then(() => {
      this.authService.saveUserInfo(this.keycloakService.loadUserProfile())
        .subscribe({
          error: console.error.bind(this),
          next: (userInfo: UserAccountDTO) => {
            this.analyticsService.addLoginKeycloakAction();
            this.tokenStorageService.saveUser(userInfo);
          },
          complete: () => this.router.navigate([redirectUrl])
        });
    });
  };


}
