import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-pwa-popup',
  templateUrl: './pwa-popup.component.html',
  styleUrls: ['./pwa-popup.component.css']
})
export class PwaPopupComponent implements OnChanges, OnInit {

  @ViewChild('pwaPopup') modal: NgbActiveModal;

  @Input() showModal: boolean;
  @Input() installPWA: () => void;
  @Output() userResponse: EventEmitter<boolean> = new EventEmitter<boolean>();

  private ngbModalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal
  ) {
    this.showModal = false;
  }

  ngOnInit(): void {}

  openModal() {
    this.ngbModalRef = this.modalService.open(this.modal, {centered: true} as NgbModalOptions);
  }

  closeModal() {
    if(this.ngbModalRef) {
      this.ngbModalRef.close();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['showModal'].currentValue === true) {
      this.openModal();
    } else {
      this.closeModal();
    }
  }
}
