import {
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {DescriptionModalComponent} from "../description-modal/description-modal.component";
import {CommercialDTO, EventDTO, ExploreDTO, XploreItemDTO} from "../../../shared/object/xplorer-be-parsed-classes";
import {TranslateService} from "@ngx-translate/core";
import {TabComponent} from "../tab/tab.component";

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.css']
})
export class InfoBoxComponent implements OnInit {

  @ViewChild('descriptionTab', {static: true})
  descriptionTab: TabComponent;

  @ViewChild('infoTab', {static: true})
  infoTab: TabComponent;

  @ViewChild('relatedTab', {static: true})
  relatedTab: TabComponent;

  tabs: TabComponent[] = [];

  @Input()
  xploreItem: XploreItemDTO;

  constructor(
    private modalService: NgbModal,
    private translation: TranslateService,
  ) { }

  ngOnInit(): void {
    this.tabs.forEach(tab => tab.active = false);
    this.descriptionTab.active = true;
    this.tabs.push(this.descriptionTab);
    this.tabs.push(this.infoTab);
    this.tabs.push(this.relatedTab);
  }

  selectTab(tab: TabComponent) {
    this.tabs.forEach(tab => tab.active = false);
    tab.active = true;
  }

  getDescription() {
    switch (this.translation.currentLang) {
      case 'it':
        return this.xploreItem.descriptionFormatted != null ? this.xploreItem.descriptionFormatted : this.xploreItem.description;
      case 'en':
        return this.xploreItem.descriptionEngFormatted != null ? this.xploreItem.descriptionEngFormatted : this.xploreItem.descriptionEng;
      default:
        return this.xploreItem.descriptionFormatted != null ? this.xploreItem.descriptionFormatted : this.xploreItem.description;
    }
  }

  getShortDescription() {
    switch (this.translation.currentLang) {
      case 'it':
        return this.xploreItem.shortDescription;
      case 'en':
        return this.xploreItem.shortDescriptionEng;
      default:
        return this.xploreItem.shortDescription;
    }
  }

  open() {
    const modalOptions: NgbModalOptions = {
      centered: true,
      scrollable: true,
      size: 'lg'
    };
    const modalRef = this.modalService.open(DescriptionModalComponent, modalOptions)
    modalRef.componentInstance.data = this.xploreItem
  }

  getPositionName() {
    return this.xploreItem.geoPositionDTO.via + " " + this.xploreItem.geoPositionDTO.numeroCivico + " - " + this.xploreItem.geoPositionDTO.comune.name
  }

  getPrice() {
    if (this.xploreItem.itemType === 'COMMERCIAL') {
      let commercial = this.xploreItem as CommercialDTO
      return commercial.price
    }
    if(this.xploreItem.itemType === 'EVENT') {
      let event = this.xploreItem as EventDTO
      return event.price
    }
    return undefined
  }

  hasDate() {
    if (this.xploreItem.itemType === "EXPLORE") {
      const explore = this.xploreItem as ExploreDTO
      return (explore.openDate !== null || explore.closeDate !== null)
    }
    if (this.xploreItem.itemType === "COMPANY") {
      const commercial = this.xploreItem as CommercialDTO
      return (commercial.openDate !== undefined || commercial.closeDate !== undefined)
    }
    if(this.xploreItem.itemType === "EVENT") {
      const event = this.xploreItem as EventDTO
      return (event.date !== undefined)
    }
    return false
  }

  getOpenDate() {
    if (this.xploreItem.itemType === "EXPLORE") {
      const explore = this.xploreItem as ExploreDTO
      return explore.openDate
    }
    if (this.xploreItem.itemType === "COMPANY") {
      const commercial = this.xploreItem as CommercialDTO
      return commercial.openDate
    }
    if(this.xploreItem.itemType === "EVENT") {
      const event = this.xploreItem as EventDTO
      return event.date
    }
    return false
  }

  isEvent() {
    return this.xploreItem.itemType === "EVENT"
  }

  getCloseDate() {
    if (this.xploreItem.itemType === "EXPLORE") {
      const explore = this.xploreItem as ExploreDTO
      return explore.closeDate
    }
    if (this.xploreItem.itemType === "COMPANY") {
      const commercial = this.xploreItem as CommercialDTO
      return commercial.closeDate
    }
    if(this.xploreItem.itemType === "EVENT") {
      const event = this.xploreItem as EventDTO
      return event.date
    }
    return false
  }

  hasHour() {
    if (this.xploreItem.itemType === "EXPLORE") {
      const explore = this.xploreItem as ExploreDTO
      return (explore.openHour !== null || explore.closeHour !== null)
    }
    if (this.xploreItem.itemType === "COMPANY") {
      const commercial = this.xploreItem as CommercialDTO
      return (commercial.openHour !== undefined || commercial.closeHour !== undefined)
    }
    if (this.xploreItem.itemType === "EVENT") {
      const event = this.xploreItem as EventDTO
      return (event.openHour && event.closeHour)
    }
    return false
  }

  getOpenHour() {
    if (this.xploreItem.itemType === "EXPLORE") {
      const explore = this.xploreItem as ExploreDTO
      return explore.openHour
    }
    if (this.xploreItem.itemType === "COMPANY") {
      const commercial = this.xploreItem as CommercialDTO
      return commercial.openHour
    }
    if(this.xploreItem.itemType === "EVENT") {
      const event = this.xploreItem as EventDTO
      return event.openHour
    }
    return false
  }

  getBookableUntil() {
    if (this.xploreItem.itemType === "EVENT") {
      const event = this.xploreItem as EventDTO
      return event.lastBookingTime
    }
    return null
  }

  getCloseHour() {
    if (this.xploreItem.itemType === "EXPLORE") {
      const explore = this.xploreItem as ExploreDTO
      return explore.closeHour
    }
    if (this.xploreItem.itemType === "COMPANY") {
      const commercial = this.xploreItem as CommercialDTO
      return commercial.closeHour
    }
    if(this.xploreItem.itemType === "EVENT") {
      const event = this.xploreItem as EventDTO
      return event.closeHour
    }
    return false
  }

  isTabActive(tab: TabComponent) {
    return tab.active
  }
}
