<div class="flex flex-col p-3 border border-gray-400 shadow-md rounded-2xl h-full">
  <div class="custom-background-color w-12 h-12 p-1 flex justify-center items-center rounded-lg">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clip-path="url(#clip0_467_738)">
        <path d="M16 7C16 6.73478 15.8946 6.48043 15.7071 6.29289C15.5196 6.10536 15.2652 6 15 6C14.7348 6 14.4804 6.10536 14.2929 6.29289C14.1054 6.48043 14 6.73478 14 7V18C14.0001 18.1763 14.0467 18.3494 14.1352 18.5018C14.2237 18.6542 14.3509 18.7806 14.504 18.868L21.504 22.868C21.7337 22.9921 22.0029 23.0215 22.254 22.9498C22.5051 22.8782 22.7181 22.7111 22.8477 22.4844C22.9772 22.2577 23.013 21.9893 22.9473 21.7366C22.8815 21.4839 22.7196 21.2669 22.496 21.132L16 17.42V7Z" fill="white"/>
        <path d="M16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30C12.287 30 8.72601 28.525 6.1005 25.8995C3.475 23.274 2 19.713 2 16C2 12.287 3.475 8.72601 6.1005 6.1005C8.72601 3.475 12.287 2 16 2C19.713 2 23.274 3.475 25.8995 6.1005C28.525 8.72601 30 12.287 30 16Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_467_738">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
  <!-- <dt class="mt-6 text-sm font-bold">{{ 'detail_modal.info_section.hour.header' | translate }}</dt> -->
  <div class="grid grid-cols-2">
    <div class="col-span-1">
      <dt class="mt-6 text-sm font-bold">{{ 'detail_modal.info_section.hour.start' | translate }}</dt>
      <dd class="mt-2 text-sm">{{openingHour | date:'HH:mm' }}</dd>
    </div>
    <div class="col-span-1">
      <dt class="mt-6 text-sm font-bold">{{ 'detail_modal.info_section.hour.end' | translate }}</dt>
      <dd class="mt-2 text-sm">{{closingHour | date:'HH:mm' }}</dd>
    </div>
  </div>
</div>
