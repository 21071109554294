import {AfterViewInit, Component, ElementRef, HostListener, Input, Renderer2, ViewChild} from '@angular/core';
import {FilterService} from '../../../services/filter.service';
import {Filter} from '../../../shared/object/filter';
import {TerritoryService} from "../../../services/geolocation/territory.service";

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.css']
})
export class FilterButtonComponent implements AfterViewInit {

  @Input() filter: Filter;
  @Input() loadedIndex;
  @Input() updateItemsFunction: () => void;
  @ViewChild('filterimage') image: ElementRef;

  isMobile: boolean = window.innerWidth < 768;

  constructor(
    private renderer: Renderer2,
    private filterService: FilterService,
    private territoryService: TerritoryService
  ) {}

  public toggle() {
    if (this.filterService.checkIfNotLastActiveFilter(this.filter.API_name)) {
      const active = this.filterService.changeFilterStatus(this.filter.API_name)
      this.renderer[active ? 'removeClass' : 'addClass'](this.image.nativeElement, 'inactive');
      this.loadedIndex = 0
      this.updateItemsFunction()
    }
  }

  ngAfterViewInit() {
    if (!this.filterService.getFilterStatus(this.filter.API_name)) {
      this.renderer['addClass'](this.image.nativeElement, 'inactive');
    }
  }

  @HostListener('window:resize', ['$event'])
  private onResize() {
    this.isMobile = window.innerWidth < 768;
  }

  getFilterImage() {
    return "/assets/xplorer/" + this.territoryService.getTerritoryCodeFromUrl() + "/filter-assets/" + this.filter.image;
  }
}
