<div class="flex flex-column h-100">
  <div class="flex-none mx-4 border-b border-gray-200">
    <ul class="grid grid-cols-3 text-sm font-medium text-center text-gray-500">
      <li (click)="selectTab(tab)" *ngFor="let tab of tabs"
          [ngClass]="tab.active ? 'custom-active-tab' : ''"
          class="button-tab">
        <a class="custom-anchor-color inline-flex pb-3 rounded-t-lg border-b-2 border-transparent group">{{tab.title}}</a>
      </li>
    </ul>
  </div>
  <div class="grow overflow-auto w-100" style="height: 0;">
<!--    TODO rework this to be a component -->
    <app-tab #descriptionTab [tabTitle]="'detail_modal.segments.description' | translate">
      <div [innerHTML]="getDescription()" class="md:hidden px-4 h-100"></div>
      <div class="hidden md:block h-100 flex flex-col m-4">
        <div [innerHTML]="getShortDescription()" class="overflow-hidden text-sm">
        </div>
        <div class="w-auto flex justify-center mt-4">
          <button (click)="open()"
                  class="custom-color text-sm font-bold py-2 px-4 rounded-full">{{ 'detail_modal.read_more' | translate }}</button>
        </div>
      </div>
    </app-tab>
    <app-tab #infoTab [tabTitle]="'detail_modal.segments.info' | translate">
      <!--    TODO rework this to be a component -->
      <div class="m-4">
        <div class="grid gap-3 grid-cols-2 sm:gap-4 md:grid-cols-1 2xl:grid-cols-2">
          <app-location-info-item *ngIf="xploreItem.geoPositionDTO.comune !== null"
                                [location]="getPositionName()"
                                [mapRef]="xploreItem.geoPositionDTO.mapsLink"></app-location-info-item>
          <app-website-info-item *ngIf="xploreItem.website !== undefined && xploreItem.website !== ''"
                               [website]="xploreItem.website"></app-website-info-item>
          <app-price-info-item *ngIf="getPrice()" [price]="getPrice()"></app-price-info-item>
          <app-phone-info-item *ngIf="xploreItem.phoneNumber !== null && xploreItem.phoneNumber !== '' "
                             [phoneNumber]="xploreItem.phoneNumber"></app-phone-info-item>
          <app-email-info-item *ngIf="xploreItem.email !== undefined && xploreItem.email !== ''"
                             [email]="xploreItem.email"></app-email-info-item>
          <app-date-info-item *ngIf="hasDate()" [endDate]="getOpenDate()" [startDate]="getCloseDate()"
                              [closeReservationsDate]="getBookableUntil()" [isAnEvent]="isEvent()"></app-date-info-item>
          <app-hour-info-item *ngIf="hasHour()" [closingHour]="getCloseHour()"
                            [openingHour]="getOpenHour()"></app-hour-info-item>
          <xplorer-bowser-instagram-info-item *ngIf="xploreItem.instagramLink !== null && xploreItem.instagramLink !== ''"
                                           [instagramLink]="xploreItem.instagramLink">
          </xplorer-bowser-instagram-info-item>
          <xplorer-bowser-facebook-info-item *ngIf="xploreItem.facebookLink !== null && xploreItem.facebookLink !== ''"
                                          [facebookLink]="xploreItem.facebookLink">
          </xplorer-bowser-facebook-info-item>
        </div>

      </div>
    </app-tab>
    <app-tab #relatedTab [tabTitle]="'detail_modal.segments.related' | translate">
      <div class="mx-4 h-100">
        <app-related-items-manager
          [isShowed]="relatedTab.active"
          [xploreItemId]="xploreItem.id"
        ></app-related-items-manager>
      </div>
    </app-tab>
  </div>
</div>
