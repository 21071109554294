import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {environment} from "../../../../environments/environment";
import {TerritoryService} from "../../../services/geolocation/territory.service";
import {ImageManager} from "../../../shared/image-manager";

@Component({
  selector: 'xplorer-bowser-location-switch-popup',
  templateUrl: './location-switch-popup.component.html',
  styleUrls: ['./location-switch-popup.component.css']
})
export class LocationSwitchPopupComponent implements OnInit {

  @ViewChild('locationSwitchModal') locationSwitchModalRef: TemplateRef<any>;

  availableTerritories: any[] = environment.AVAILABLE_TERRITORY;

  constructor(private modalService: NgbModal,
              private territoryService: TerritoryService) { }

  ngOnInit(): void {
  }

  openModal() {
    this.modalService.open(this.locationSwitchModalRef, {
      centered: true,
    });
  }

  getActiveTerritory() {
    this.availableTerritories.filter((territory) => {
      if (territory.code === this.territoryService.getTerritoryCodeFromUrl()) {
        return territory;
      }
    });
  }

  protected readonly ImageManager = ImageManager;

  getCurrentTerritoryCode() {
    return this.territoryService.getTerritoryCodeFromUrl();
  }

  changeTerritory(selectedTerritory: any) {
    if(selectedTerritory.code === this.territoryService.getTerritoryCodeFromUrl()) {
      return;
    }

    let newUrl = selectedTerritory.url;

    if (environment.IS_LOCAL) {
      console.info("Local environment, redirecting to: " + newUrl);
      return;
    }

    window.location.href = newUrl;

  }
}
