import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-description-modal',
  templateUrl: './description-modal.component.html',
  styleUrls: ['./description-modal.component.css'],
})
export class DescriptionModalComponent implements OnInit {
  @Input() public data;

  constructor(
    private activeModal: NgbActiveModal,
    private translation: TranslateService
  ) {
  }

  getDescription() {
    switch (this.translation.currentLang) {
      case 'it':
        return this.data.descriptionFormatted != null ? this.data.descriptionFormatted : this.data.description;
      case 'en':
        return this.data.descriptionEngFormatted != null ? this.data.descriptionEngFormatted : this.data.descriptionEng;
      default:
        return this.data.descriptionFormatted != null ? this.data.descriptionFormatted : this.data.description;
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close()
  }

}
