import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "../auth/auth.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
  constructor(private readonly authService: AuthService, private readonly router: Router) { }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if(environment.UNSECURE) {
      return true;
    }

    if (!this.authService.isUserLogged()) {
      this.authService.logout()
        .subscribe(() => {
          this.router.navigate(['explore']);
        });
      return false;
    }

    return this.authService.getUser().roles.includes('ROLE_ADMIN');
  }
}
