import {Component, Input} from '@angular/core';
import {XploreItemDTO} from "../../../shared/object/xplorer-be-parsed-classes";
import {environment} from "../../../../environments/environment";
import {ImageManager} from "../../../shared/image-manager";

@Component({
  selector: 'app-preview-item-card',
  templateUrl: './preview-item-card.component.html',
  styleUrls: ['./preview-item-card.component.css']
})
export class PreviewItemCardComponent {
  @Input() xploreItem: XploreItemDTO

  getImage() {
    return this.xploreItem.multiMedia[0] ? environment.IMAGES_URL + this.xploreItem.multiMedia[0].mediaURL : ImageManager.getPlaceholderImage();
  }
}
