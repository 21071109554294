import {KeycloakService} from "keycloak-angular";
import {environment} from "../../../environments/environment";

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const REFRESH_TOKEN = 'refresh-token';

export const initializeKeycloak = (keycloak: KeycloakService) => {
  return async () => {

    if(localStorage.getItem(TOKEN_KEY) && localStorage.getItem(REFRESH_TOKEN)) {
      console.debug('[KEYCLOAK] Keycloak initialized with token from local storage');
      console.debug('[KEYCLOAK] Token: ', localStorage.getItem(TOKEN_KEY));
      console.debug('[KEYCLOAK] Refresh Token: ', localStorage.getItem(REFRESH_TOKEN));
      await keycloak.init({
        config: {
          url: environment.KEYCLOAK_URL,
          realm: environment.KEYCLOAK_REALM,
          clientId: environment.KEYCLOAK_CLIENT_ID,
        },
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/silent-check-sso.html',
          checkLoginIframe: true,
          refreshToken: localStorage.getItem(REFRESH_TOKEN)
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets', '/clients/public'],
      })
    } else {
      console.debug('[KEYCLOAK] Keycloak initialized with new token');
      await keycloak.init({
        config: {
          url: environment.KEYCLOAK_URL,
          realm: environment.KEYCLOAK_REALM,
          clientId: environment.KEYCLOAK_CLIENT_ID,
        },
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/silent-check-sso.html',
          checkLoginIframe: true
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets', '/clients/public'],
      })
    }
  }
};
