import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { XploreItemDTO } from "../../../shared/object/xplorer-be-parsed-classes";
import { ReservationService } from "../../../services/reservation.service";
import { AuthService } from "../../../services/auth/auth.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ToastService } from "../../../services/toast/toast.service";
import {Router} from "@angular/router";

@Component({
  selector: 'book-section',
  templateUrl: './book-section.component.html',
  styleUrls: ['./book-section.component.css']
})
export class BookSectionComponent implements OnInit, OnDestroy {

  @Input()
  xploreItem: XploreItemDTO;

  hasUserReserved: boolean = false;
  availableReservations: number;
  isLoading: boolean = true;
  private onDestroy$: Subject<void> = new Subject<void>()
  toggleModal: boolean = false;
  isMobile: boolean = window.innerWidth < 768;

  constructor(
    private reservationService: ReservationService,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.initAvailableReservationsNumber();
    this.initAlreadyReserved();
  }

  private initAvailableReservationsNumber() {
    this.reservationService.getAvailableReservations(this.xploreItem.id)
      .pipe(
        takeUntil(this.onDestroy$),
      ).subscribe({
      next: (places) => this.availableReservations = places,
      error: (error) => {
        this.toastService.showErrorToast(`Error: ${error.error.message}`);
      }
    });
  }

  private initAlreadyReserved() {
    this.authService.isUserLogged()
        .subscribe({
          next: (isUserLogged: boolean) => {
            if (!isUserLogged) {
              this.isLoading = false;
              this.hasUserReserved = false;
            } else {
              this.retrieveUserReservation();
            }
          }
        })
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  openModal() {
    this.authService.isUserLogged()
      .subscribe({
        next: (isUserLogged: boolean) => {
          if (!isUserLogged) {
            let redirectUrl;

            if(this.isMobile) {
              redirectUrl = "/explore/info/" + this.xploreItem.id
            } else {
              redirectUrl = "/explore/" + this.xploreItem.id
            }

            console.debug(redirectUrl);
            this.router.navigate(['login'], {state: {source: redirectUrl}})
          } else {
            this.toggleModal = !this.toggleModal;
          }
        }
      })
  }

  onReservationEvent() {
    this.isLoading = true;
    this.retrieveUserReservation();
  }

  private retrieveUserReservation() {
    this.reservationService.getReservationByUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (reservations) => {
          let reservationsIds = reservations.map(reservation => reservation.eventId)
          this.hasUserReserved = reservationsIds.includes(this.xploreItem.id);
          this.isLoading = false;
        },
        error: (error) => {
          this.toastService.showErrorToast(`Error: ${error.error.message}`);
        }
      });
  }
}
