<div class="pointer" (click)="openMaps();">
  <div class="flex flex-col p-3 border border-gray-400 shadow-md rounded-2xl h-full">
    <div class="custom-background-color w-12 h-12 p-1 flex justify-center items-center rounded-lg">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="33" viewBox="0 0 25 33" fill="none">
        <g clip-path="url(#clip0_465_628)">
          <path opacity="0.8" d="M16.3727 12.2931C16.3727 14.965 14.2352 17.0432 11.6227 17.0432C8.95079 17.0432 6.87266 14.965 6.87266 12.2931C6.87266 9.68062 8.95079 7.54311 11.6227 7.54311C14.2352 7.54311 16.3727 9.68062 16.3727 12.2931ZM11.6227 15.1431C13.1664 15.1431 14.4727 13.8962 14.4727 12.2931C14.4727 10.7494 13.1664 9.44311 11.6227 9.44311C10.0195 9.44311 8.77266 10.7494 8.77266 12.2931C8.77266 13.8962 10.0195 15.1431 11.6227 15.1431ZM23.0227 12.2931C23.0227 17.5181 16.0758 26.7213 12.9883 30.5806C12.2758 31.4712 10.9102 31.4712 10.1977 30.5806C7.11016 26.7213 0.222656 17.5181 0.222656 12.2931C0.222656 5.99937 5.26954 0.893112 11.6227 0.893112C17.9164 0.893112 23.0227 5.99937 23.0227 12.2931ZM11.6227 2.79311C6.33829 2.79311 2.12266 7.06812 2.12266 12.2931C2.12266 13.2431 2.41954 14.49 3.07266 16.0931C3.72578 17.6368 4.6164 19.2993 5.68516 20.9619C7.70391 24.2275 10.0789 27.3744 11.6227 29.2744C13.107 27.3744 15.482 24.2275 17.5007 20.9619C18.5695 19.2993 19.4601 17.6368 20.1132 16.0931C20.7663 14.49 21.1227 13.2431 21.1227 12.2931C21.1227 7.06812 16.8476 2.79311 11.6227 2.79311Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_465_628">
            <rect width="24" height="32" fill="white" transform="translate(0.222656 0.293114)"/>
          </clipPath>
        </defs>
      </svg>
    </div>

    <dt class="mt-6 text-sm font-bold">{{ 'detail_modal.info_section.place' | translate }}</dt>
    <div class="col-span-2 my-auto">
      <dd class="mt-2 text-sm break-words">{{location}}</dd>
    </div>
  </div>
</div>
