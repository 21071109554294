<div *ngIf="(!relatedXploreItems || relatedXploreItems?.length === 0) && !isLoadingRelatedItems"
     class="w-100 h-100 d-flex justify-content-center mt-5">
  <h2 class="font-bold">{{"related_not_available_title" | translate}}</h2>
</div>

<div *ngIf="relatedXploreItems?.length > 0  && !isLoadingRelatedItems"
  class="bg-white grid grid-cols-2 pt-2 gap-3 mb-2">
  <app-preview-item-card *ngFor="let xploreItem of relatedXploreItems"
                         [xploreItem]="xploreItem"></app-preview-item-card>

</div>

<div class="mt-32" *ngIf="isLoadingRelatedItems">
  <div class="height-without-bar flex align-items-center justify-content-center">
    <div class="spinner-border custom-big-spinner"
         role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
