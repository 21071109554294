<button (click)="toggle()" class="h-100 w-100">
  <div class="relative h-100 w-100">
    <h1 *ngIf="!isMobile"
        class="z-10 drop-shadow-xl text-white text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      {{'filters.' + filter.name | translate}}
    </h1>
    <h1 *ngIf="isMobile"
        class="z-10 drop-shadow-xl text-white text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <!--    TODO handle the text differently  -->
      {{'filters.' + filter.name | translate}}
    </h1>
    <img #filterimage alt="filter image" class="object-cover w-100 h-100 rounded-lg"
         [src]="getFilterImage()">
  </div>
</button>
