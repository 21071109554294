<div class="h-16 bottom-0">
  <ul class="h-full p-1 flex text-sm font-medium text-center bg-white shadow">
    <li class="w-full">
      <a class="tab-style" routerLink="/search" routerLinkActive="custom-tab-active">
        <div class="flex-col flex items-center text-gray-400">
          <svg class="h-6 w-6" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
               stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use [attr.xlink:href]="ImageManager.searchIcon"/>
          </svg>
        </div>
      </a>
    </li>

    <li class="w-full">
      <a class="tab-style" routerLink="/map" routerLinkActive="custom-tab-active">
        <div class="flex-col flex items-center text-gray-400">
          <svg class="w-7 h-7" fill="none" stroke="currentColor" stroke-width="1.7"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">\
            <use [attr.xlink:href]="ImageManager.mapIcon"/>
          </svg>
        </div>
      </a>
    </li>

    <li class="w-full">
      <a class="tab-style no-context-menu" routerLink="/explore" routerLinkActive="custom-tab-active" longPress (longPressEmitter)="onLongPress()">
        <div class="flex-col flex items-center text-gray-400 ">
          <img alt="Explore icon" class="w-9 h-9 tab-explore-inactive" routerLinkActive="tab-explore-active"
               [src]="ImageManager.getLogoIconSvg()">
        </div>
      </a>
    </li>

    <li class="w-full">
      <a class="tab-style" routerLink="/favorites" routerLinkActive="custom-tab-active">
        <div class="flex-col flex items-center text-gray-400">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="2"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use [attr.xlink:href]="ImageManager.bookmarkBookIcon"/>
          </svg>
        </div>
      </a>
    </li>

    <li class="w-full">
      <a class="tab-style" routerLink="/profile" routerLinkActive="custom-tab-active">
        <div class="flex-col flex items-center text-gray-400">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="2"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use [attr.xlink:href]="ImageManager.userIcon"/>
          </svg>
<!--          <p class="text-xxsm font-regular">{{'tabs.profile' | translate}}</p>-->
        </div>
      </a>
    </li>
  </ul>
</div>


<xplorer-bowser-location-switch-popup #switchPopupComponent>
</xplorer-bowser-location-switch-popup>
