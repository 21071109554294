import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'xplorer-bowser-facebook-info-item',
  templateUrl: './facebook-info-item.component.html',
  styleUrls: ['./facebook-info-item.component.css']
})
export class FacebookInfoItemComponent implements OnInit {

  @Input()
  facebookLink: string;

  constructor() { }

  ngOnInit(): void {
  }

  openFacebook() {
    open(this.facebookLink, '_blank').focus();
  }
}
