import {AfterContentInit, Component, ContentChildren, Input, OnInit, QueryList} from '@angular/core';
import {TabComponent} from "../../components/tab/tab.component";
import {TagDTO, XploreItemDTO} from "../../../shared/object/xplorer-be-parsed-classes";
import {takeUntil} from "rxjs/operators";
import {XploreItemService} from "../../../services/xplore-item.service";
import {Subject, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MetaDataService} from "../../../services/meta/meta-data.service";

@Component({
  selector: 'app-xplore-item-info-page',
  templateUrl: './xplore-item-info-page.component.html',
  styleUrls: ['./xplore-item-info-page.component.css']
})
export class XploreItemInfoPageComponent implements OnInit, AfterContentInit {

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  xploreItem: XploreItemDTO;
  id: string;
  loading: boolean = false;
  errorHappened: boolean = false;
  isMobile: boolean = window.innerWidth < 768;
  private mainServiceSubscription$: Subscription;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private xploreItemService: XploreItemService,
    private metaDataService: MetaDataService
  ) {
  }

  ngOnInit(): void {
    this.loading = true
    this.id = this.route.snapshot.paramMap.get('id')
    this.mainServiceSubscription$ = this.xploreItemService.getXploreItemById(this.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(explore => {
        if (explore != null) {
          this.xploreItem = explore
          if (!this.isMobile) {
            this.router.navigate(['explore/' + this.xploreItem.id]).then();
          }
        }
        this.metaDataService.updateMetaDataByXploreItem(this.xploreItem)
        this.loading = false
      }, error => {
        this.loading = false
        this.errorHappened = true
      });
  }

  ngAfterContentInit() {
    console.debug(this.isMobile)
    let activeTabs = this.tabs.filter((tab) => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  isEvent() {
    return this.xploreItem.itemType === 'EVENT'
  }

  selectTab(tab: TabComponent) {
    this.tabs.toArray().forEach(tab => tab.active = false);
    if (tab) {
      tab.active = true;
    }
  }

  goBackToItemCard() {
    this.router.navigate(['explore/' + this.xploreItem.id]);
  }

  get firstLevelTags(): TagDTO[] {
    return this.xploreItem.tags.filter(tag => tag.tagLevel === 0);
  }

  buildTagName(tag: TagDTO) {
    let tagName = tag.code;
    tagName = tagName.replace(/_/g, ' ');
    tagName = tagName.charAt(0).toUpperCase() + tagName.slice(1);
    return tagName;
  }

  goToSearchTag(tag: TagDTO) {
    this.router.navigate(['search/' + tag.code]);
  }
}
