import {Component, ElementRef, Inject, Input, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {HotToastRef, HotToastService} from "@ngneat/hot-toast";
import {ToastType} from "../../object/toast";
@Component({
  selector: 'app-info-toast',
  templateUrl: './info-toast.component.html',
  styleUrls: ['./info-toast.component.css']
})
export class InfoToastComponent {

  type: ToastType;
  message: string = '';
  constructor(
    @Optional() @Inject(HotToastRef) public toastRef: HotToastRef<any>
  ) {
    if (toastRef) {
      this.type = toastRef.data?.type;
      this.message = toastRef.data?.message;
    }
  }

  protected readonly ToastType = ToastType;
}
