import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-info-item',
  templateUrl: './date-info-item.component.html',
  styleUrls: ['./date-info-item.component.css']
})
export class DateInfoItemComponent {

  @Input() isAnEvent = false
  @Input() startDate
  @Input() endDate
  @Input() closeReservationsDate
}
