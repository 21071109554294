<ng-template #pwaPopup>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'pwa.popup.title' | translate }}
    </h4>
    <button
      type="button"
      class="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5"
      aria-describedby="modal-title"
      (click)="closeModal()">
      <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              fill-rule="evenodd">
        </path>
      </svg>
    </button>
  </div>
  <div class="modal-body text-center">
    {{ 'pwa.popup.text' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">
      {{ 'pwa.popup.later' | translate }}
    </button>
    <button type="button" class="btn custom-color"
            (click)="installPWA()">
      {{ 'pwa.popup.install' | translate }}
    </button>
  </div>
</ng-template>

<!--<div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">-->
<!--    <div class="relative w-auto my-6 mx-auto max-w-2sm">-->
<!--        &lt;!&ndash;content&ndash;&gt;-->
<!--        <div-->
<!--            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">-->
<!--            &lt;!&ndash;header&ndash;&gt;-->
<!--            <div class="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">-->
<!--                <h3 class="text-xl font-semibold">-->
<!--                    Installa app!-->
<!--                </h3>-->
<!--            </div>-->
<!--            &lt;!&ndash;body&ndash;&gt;-->
<!--            <div class="relative p-6 flex-auto">-->
<!--                <p class="my-4 text-slate-500 text-lg leading-relaxed">-->
<!--                    Per un'esperienza migliore, scarica la nostra app sul tuo device! 😉-->
<!--                </p>-->
<!--            </div>-->
<!--            &lt;!&ndash;footer&ndash;&gt;-->
<!--            <div class="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">-->
<!--                <button-->
<!--                    class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"-->
<!--                    type="button" (click)="showModal = false; userResponse.emit(false)">-->
<!--                    No, grazie-->
<!--                </button>-->
<!--                <button-->
<!--                    class="text-emerald-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"-->
<!--                    type="button" (click)="showModal = false; installPWA();">-->
<!--                    Si, installa!-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<!--<div *ngIf="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>-->
