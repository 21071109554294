import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HTTP_INTERCEPTORS
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import {AuthInterceptor} from "./auth.interceptor";
import {AuthService} from "../auth/auth.service";

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  constructor(private readonly authService: AuthService, private readonly router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request);
  }
}

export const errorInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true}
];
