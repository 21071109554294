import {Component, OnInit} from "@angular/core";
import {KeycloakService} from "keycloak-angular";

@Component({
  selector: 'xplorer-bowser-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.css']
})
export class LogoutPageComponent {

  constructor(
    private readonly keycloakService: KeycloakService
  ) {
    this.keycloakService.logout();
  }

}
