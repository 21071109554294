import {
  AfterContentInit,
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {XploreItemService} from '../../../services/xplore-item.service';
import {XploreItemDTO} from '../../../shared/object/xplorer-be-parsed-classes';
import {catchError, EMPTY, map, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SwiperOptions} from 'swiper';
import SwiperCore, {Virtual} from 'swiper';
import {ActivatedRoute, Router} from "@angular/router";
import {MetaDataService} from "../../../services/meta/meta-data.service";
import {UserGeolocationService} from "../../../services/geolocation/user-geolocation.service";
import { Location } from '@angular/common';
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {TerritoryService} from "../../../services/geolocation/territory.service";
import {environment} from "../../../../environments/environment";
import {ThemeService} from "../../../services/theme.service";

SwiperCore.use([Virtual]);

@Component({
  selector: 'app-xplore-page',
  templateUrl: './xplore-page.component.html',
  styleUrls: ['./xplore-page.component.css']
})
export class XplorePageComponent implements OnInit, OnDestroy {
  @ViewChild('descriptionBox') descriptionBox;

  @Input()
  xploreItem: XploreItemDTO;

  xploreItems: XploreItemDTO[] = [];
  loadedIndex: number = 0;
  loading: boolean = false;
  errorHappened: boolean = false;

  public itemVerticalSwiperConfig: SwiperOptions = {
    virtual: true,
    slidesPerView: 1,
    direction: 'vertical',
    preloadImages: false,
    spaceBetween: 0,
    resistanceRatio: 0,
    autoHeight: true
  };
  isMobile: boolean = window.innerWidth < 768;
  private onDestroy$: Subject<void> = new Subject<void>();

  private canChangeUrl: boolean = false;
  private hasOneSelected: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private xploreItemService: XploreItemService,
    private metaDataService: MetaDataService,
    private router: Router,
    private location: Location,
    private analyticsService: AnalyticsService,
    private territoryService: TerritoryService
  ) {
  }

  ngOnInit(): void {
    this.loading = true;

    if (this.xploreItem) {
      this.xploreItems.push(this.xploreItem);
      this.loading = false;
      this.handleMetaTags(this.xploreItem);
      return;
    }

    const id = this.route.snapshot.paramMap.get('id');
    if (id) {

      this.analyticsService.addXploreVisitAction(Number(id))

      this.hasOneSelected = true;
      this.xploreItemService.getXploreItemById(id)
        .pipe(
          takeUntil(this.onDestroy$),
          map(xploreItem => {
            if(!xploreItem) {
              this.router.navigate(['/']);
            }
            this.xploreItems.push(xploreItem)
            this.handleMetaTags(xploreItem);
            this.changeCurrenUrl(xploreItem);
            this.loading = false;
          }),
          catchError(error => {
            this.errorHappened = true
            this.loading = false;
            return EMPTY;
          })
        ).subscribe();
    } else {
      this.handleMetaTags(null);
    }

    this.getXploreItemsPaged();
  }

  private changeCurrenUrl(xploreItem: XploreItemDTO) {

    if(xploreItem.territory.code !== this.territoryService.getTerritoryCodeFromUrl()) {
      if(environment.IS_LOCAL) {
        console.debug("Overwriting territory code")
        return;
      }

      window.location.href = TerritoryService.addTerritoryCodeToUrl(environment.DEFAULT_ITEM_URL + xploreItem.id, xploreItem.territory.code)
    }

    this.location.go(`/explore/${xploreItem.id}/` + xploreItem.name.toLowerCase()
      .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-')
      .replace(/ /g, `-`)
      .substring(0, 40));
  }

  onSlideChange(event) {
    let actualSlideIndex = event[0].activeIndex
    let direction = event[0].swipeDirection
    let currentXplore = this.xploreItems[actualSlideIndex];
    this.changeCurrenUrl(currentXplore);
    if (actualSlideIndex == this.xploreItems.length - 3 && direction == "next") {
      this.getXploreItemsPaged();
    }
  }

  private getXploreItemsPaged = () => {
    this.xploreItemService.getPagedXploreItems(this.loadedIndex)
      .pipe(
        takeUntil(this.onDestroy$),
        map(xploreItems => {
          //TODO handle the case of no xploreItems
          if(!xploreItems) return;
          this.xploreItems.push(...xploreItems)
          this.loadedIndex++;
          if(!this.hasOneSelected) {
            this.changeCurrenUrl(xploreItems[0]);
          }
          this.loading = false;
          // FIXME not the best solution, but it works
          setTimeout(() => this.canChangeUrl = true,500);
        }),
        catchError(error => {
          console.debug(error)
          this.errorHappened = true
          this.loading = false;
          return EMPTY;
        })
      ).subscribe();
  }

  onScroll() {
    this.getXploreItemsPaged();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 768;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private handleMetaTags(xploreItem: XploreItemDTO) {
    this.metaDataService.updateMetaDataByXploreItem(xploreItem);
  }

  onXploreInViewport(xploreItemDTO: XploreItemDTO) {
    if (!this.canChangeUrl) {
      return;
    }
    this.changeCurrenUrl(xploreItemDTO);
  }
}
