<div class="height-without-bar overflow-y-auto flex flex-col">
  <header class="sticky top-0 h-16 bg-white shadow">
    <h1
      class="py-3 px-4 text-3xl sm:px-6 lg:px-14 font-bold tracking-tight text-gray-900">{{'tabs.favorites' | translate}}</h1>
  </header>
  <div *ngIf="isLoading" class="w-100 h-100 flex justify-content-center align-items-center">
    <div class="spinner-border custom-big-spinner"
         role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="!isLoading && favorites?.length > 0"
       class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 mx-2 mt-2"
       infiniteScroll>
    <app-preview-item-card *ngFor="let item of favorites" [xploreItem]="item" class="m-3"></app-preview-item-card>
  </div>
  <div *ngIf="!isLoading && (!favorites || favorites?.length == 0)"
       class="h-100 flex justify-content-center align-items-center md:px-0 px-10">
    <div class="row d-flex justify-content-center">
      <div class="col-12 d-flex justify-content-center">
        <span class="text-center font-bold">{{ 'favorites_page.no_favorites' | translate }}</span>
      </div>
      <div class="col-12 d-flex justify-content-center">
            <span class="text-center">
              {{ 'favorites_page.no_favorites_text' | translate }}
            </span>
      </div>
    </div>
  </div>
</div>

