import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-location-info-item',
  templateUrl: './location-info-item.component.html',
  styleUrls: ['./location-info-item.component.css']
})
export class LocationInfoItemComponent {
  @Input() location: string;
  @Input() mapRef: string;

  openMaps(): void {
    open(this.mapRef, '_blank').focus();
  }
}
