import { MultiMediaDTO } from "./xplorer-be-parsed-classes";


export class Filter {
  name: string;
  API_name: string;
  image: MultiMediaDTO;
  //active: boolean;

  constructor(name, d_name, url) {
    this.name = name;
    this.API_name = d_name;
    this.image = url;
    //this.active = true;
  }
}
