import { Injectable } from '@angular/core';
import {HotToastService} from "@ngneat/hot-toast";
import {InfoToastComponent} from "../../shared/components/info-toast/info-toast.component";
import {ToastTextType, ToastType} from "../../shared/object/toast";
import {TranslateService} from "@ngx-translate/core";

/**
 * ToastService
 * Handle the toast messages
 */
@Injectable({
  providedIn: 'root'
})
export class ToastService {

  /**
   * List of components that HotToastService will handle
   * Each component represents a type of toast, and contains the html template of that toast
   */
  infoToastComponent = InfoToastComponent;

  constructor(
    private toast: HotToastService,
    private translateService: TranslateService
  ) {}

  showCustomSuccessToast(message: string, duration: number = 2000) {
    this.toast.close();
    this.toast.show(this.infoToastComponent, {duration: duration, data: {type: ToastType.SUCCESS, message}});
  }

  showSuccessToast(messageType: ToastTextType, duration: number = 2000) {
    this.toast.close();
    this.toast.show(this.infoToastComponent, {duration: duration, data: {type: ToastType.SUCCESS, message: this.translateService.instant(`toast.messages.` + messageType)}});
  }

  showErrorToast(message: string, duration: number = 2000) {
    this.toast.close();
    this.toast.show(this.infoToastComponent, {duration: duration, data: {type: ToastType.ERROR, message: message}});
  }

  showWarningToast(message: string, duration: number = 2000) {
    this.toast.close();
    this.toast.show(this.infoToastComponent, {duration: duration, data: {type: ToastType.WARNING, message: message}});
  }

  showInfoToast(message: string, duration: number = 2000) {
    this.toast.close();
    this.toast.show(this.infoToastComponent, {duration: duration, data: {type: ToastType.INFO, message: message}});
  }

  showSuccessToastWithMessage(message: string, duration: number = 2000) {
    this.toast.close();
    this.toast.show(this.infoToastComponent, {duration: duration, data: {type: ToastType.SUCCESS, message: message}});
  }

}
