import {environment} from "../../environments/environment";

export class ImageManager {
  public static instagramLogo = "/assets/icon/instagram_logo.svg";

  public static italianFlagIcon = "/assets/icon/it_flag.svg"
  public static englishFlagIcon = "/assets/icon/en_flag.svg"

  public static bookmarkBookIcon = "/assets/xplorer/shared/icons/tabbar-group.svg#bookmark-book";
  public static mapIcon = "/assets/xplorer/shared/icons/tabbar-group.svg#map";
  public static searchIcon = "/assets/xplorer/shared/icons/tabbar-group.svg#magnifier";
  public static userIcon = "/assets/xplorer/shared/icons/tabbar-group.svg#user-icon";

  public static bookmarkIcon = "/assets/xplorer/shared/icons/bookmark.svg#default";

  public static immersiveIcon = "/assets/xplorer/shared/icons/3d-icon.svg#default";
  public static shareIcon = "/assets/xplorer/shared/icons/send.svg#default";
  public static cameraIcon = "/assets/xplorer/shared/icons/camera.svg#default";

  public static blackHoleIcon = "/assets/xplorer/shared/icons/black-hole.svg#default";

  public static checkIcon = "/assets/xplorer/shared/icons/check.svg#default";

  static getLogoIconSvg(): string {
    return "/assets/xplorer/" + getTerritoryCodeFromUrl() + "/logo/logo.svg";
  }

  static getWhiteLogoSvg(): string {
    return "/assets/xplorer/" + getTerritoryCodeFromUrl() + "/logo/logo_white.svg";
  }

  static getLogoIconSvgByTerritoryCode(territoryCode: string): string {
    return "/assets/xplorer/" + territoryCode + "/logo/logo.svg";
  }

  static getLogoXplorerFull(): string {
    return "/assets/xplorer/" + getTerritoryCodeFromUrl() + "/logo/logo_full_platform.png";
  }

  static getLogoXplorerFullByTerritoryCode(territoryCode: string): string {
    return "/assets/xplorer/" + territoryCode + "/logo/logo_full_platform.png";
  }

  static getPlaceholderImage(): string {
    return "/assets/img_placeholder_9_16.jpg";
  }
}

function getTerritoryCodeFromUrl(): string {
  let windowUrl =  window.location.href;
  if (environment.IS_LOCAL) {
    windowUrl = "https://mugello.xplorer-app.com/explore";
  }
  let subdomain = windowUrl.split(".")[0];
  return subdomain.split("//")[1];
}

