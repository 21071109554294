<div *ngIf="errorHappened; else content" class="height-without-bar">
  <div class="h-100 w-100 flex align-items-center">
    <div class="flex flex-column justify-content-center h-fit w-100">
      <img class='mx-auto h-36 w-36' src="../../../../assets/dizzy-robot.png">
      <p class="text-lg mx-auto">{{ 'alter.msg' | translate }}</p>
    </div>
  </div>
</div>

<ng-template #content>
  <div (scroll)="isScrollActive && onWindowScroll($event)" class="height-without-bar bg-white overflow-y-auto">
    <div class="sticky top-0">
      <ngb-accordion #filterAccordion>
        <ngb-panel id="filters">
          <ng-template let-opened="opened" ngbPanelHeader>
            <div [class.collapsed]="!opened"
                 class="bg-white accordion-button custom-header flex gap-3 justify-content-between">
              <h1 *ngIf="!isUsingSearchBar || desktop"
                  class="text-3xl font-bold text-gray-900 ">{{'tabs.search' | translate}}</h1>
              <search-input (isSearchingEmitter)="this.isUsingSearchBar = $event" (updateItems)="updateItems()"
                            class="flex-grow sm:flex-none sm:w-1/2 md:w-1/3"></search-input>
              <button (click)="toggleAccordion($event)" class="btn btn-link p-0">
                <svg class="text-black w-8 h-8 pr-2" fill="none" stroke="currentColor" stroke-width="2"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div
              class="px-4 py-2 shadow w-100 grid custom-gap 4 grid-cols-3 sm:grid-cols-6">
              <app-filter-button *ngFor="let filter of filters" [filter]="filter" [loadedIndex]="loadedIndex"
                                 [updateItemsFunction]="updateItems" class="h-20"></app-filter-button>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
    <div *ngIf="loading; else results"
         class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 mx-2 mt-2">
      <app-placeholder-card *ngFor="let f of [].constructor(12)" class="m-3"></app-placeholder-card>
    </div>
    <ng-template #results>
      <div (scrolled)="onScroll()" [infiniteScrollDistance]="2"
           [infiniteScrollThrottle]="50"
           class="grow grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 mx-1 mt-1"
           id="box"
           infiniteScroll>
        <app-preview-item-card *ngFor="let item of showingItems" [xploreItem]="item"
                               class="m-3"></app-preview-item-card>
      </div>
    </ng-template>
  </div>
</ng-template>


